import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import Header2 from "../../Components/Header/header2";
import BronzeInfo from "./BronzeInfo";
import FreeInfo from "./FreeInfo";
import GoldInfo from "./GoldInfo";
import { connect } from "react-redux";
import "./MemberShipCheckout.css";
import SignUpForm from "./SignUpForm";
import SilverInfo from "./SilverInfo";
import UserAgreeMent from "./UserAgreeMent";

import { toast } from "react-toastify";
import { signUpfn } from "../../Store/actions/auth";
import { useNavigate } from "react-router-dom";
import Loader from "../../Components/Loader/loader";
function MembershipCheckout(props) {
  const [params, setParams] = useState("");
  const [signupobj, setSignupobj] = useState({});
  const [password, setPassword] = useState("");
  const [aggrement, setAggrement] = useState(false);
  const [Loading, setLoading] = useState(false);
  const navigation = useNavigate();
  let url = new URL(window.location);
  let type = url.searchParams.get("type");
  useEffect(() => {
    console.log(type);
    setParams(type);
  }, [type]);
  const cleanState = () => {
    setSignupobj({});
    setPassword("");
    setAggrement(false);
    document.getElementById("SubmitForm").reset();
  };
  let emailreg = /^([a-z0-9_\.-]+)@([\da-z\.-]+)\.([a-z\.]{2,63})$/;
  const onChange = () => {
    if (
      signupobj?.userName?.length &&
      signupobj?.email.match(emailreg) &&
      password === signupobj?.password &&
      aggrement
    ) {
      setLoading(true);
      props.signUpfn(signupobj, navigation, setLoading, cleanState);
    } else {
      toast.error(
        !signupobj?.userName?.length
          ? "please enter your name"
          : !signupobj?.email.match(emailreg)
          ? "invalid email"
          : password !== signupobj?.password
          ? "your password don't match"
          : !aggrement
          ? "please accept user aggrement"
          : "invalid"
      );
    }
  };
  return (
    <>
      <Header2 text={"MEMBERSHIP CHECKOUT"} />
      <div className="MEMBERSHIP">
        <div className="MEMBERSHIP-Inner">
          {params === "Bronze" ? (
            <BronzeInfo />
          ) : params === "Silver" ? (
            <SilverInfo />
          ) : params === "Gold" ? (
            <GoldInfo />
          ) : (
            <FreeInfo />
          )}
          <hr />
          <SignUpForm
            onChange={onChange}
            signupobj={signupobj}
            setSignupobj={setSignupobj}
            setPassword={setPassword}
            password={password}
          />
          <hr />
          <UserAgreeMent />
          <p className="checkbox">
            <input
              type="checkbox"
              value={aggrement}
              onChange={(ev) => setAggrement(ev.target.checked)}
            />
            click here to Accept user aggrement
          </p>
          <button className="Register" onClick={onChange} disabled={Loading}>
            {Loading ? <Loader /> : "Register"}
          </button>
        </div>
      </div>
    </>
  );
}
const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  signUpfn: (data, navigation, setloader, cleanState) =>
    dispatch(signUpfn(data, navigation, setloader, cleanState)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MembershipCheckout);
