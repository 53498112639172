import axios from "axios";
import { toast } from "react-toastify";
import { BACKEND_URL } from "../constant";
export const USER_DATA = "USER_DATA";
export const getUserData = (payload) => {
  return {
    type: USER_DATA,
    payload: payload,
  };
};
export const signUpfn = (data, navigation, setloader, cleanState) => {
  return (dispatch) => {
    console.log(data);
    var config = {
      method: "post",
      url: `${BACKEND_URL}/user/signup`,
      data: { ...data, userType: "user", subscriptionType: "free" },
    };
    axios(config)
      .then(function (response) {
        if (response?.data?.userExists) {
          toast.error(response?.data?.message || "invalid response");
          setloader(false);
          cleanState();
        } else {
          console.log(response);
          setloader(false);
          cleanState();
          toast.success("user created successfully");
          navigation("/Login");
        }
      })
      .catch(function (error) {
        toast.error(error.response?.data?.message || "invalid response");
        setloader(false);
      });
  };
};
export const signinfn = (data, navigation, setloader, cleanState) => {
  return (dispatch) => {
    console.log(data);
    var config = {
      method: "post",
      url: `${BACKEND_URL}/user/login`,
      data: data,
    };
    axios(config)
      .then(function (response) {
        console.log(response);
        localStorage.setItem(
          "authToken",
          JSON.stringify(response?.data?.token)
        );
        localStorage.setItem(
          "auth_id",
          JSON.stringify(response?.data?.user?._id)
        );
        cleanState({});
        console.log([response?.data?.user]);
        dispatch(getUserData([response?.data?.user]));
        document.getElementById("Login-Submit").reset();
        toast.success("user login successfully");
        navigation("/");
        setloader(false);
      })
      .catch(function (error) {
        toast.error(error.response?.data?.message || "invalid response");
        setloader(false);
      });
  };
};
export const userDatafn = () => {
  return (dispatch) => {
    var config = {
      method: "get",
      url: `${BACKEND_URL}/user/userData?id=${JSON.parse(
        localStorage.getItem("auth_id")
      )}`,
      headers: {
        token: localStorage.getItem("authToken"),
      },
    };
    axios(config)
      .then(function (response) {
        dispatch(getUserData(response?.data?.user));
      })
      .catch(function (error) {
        if (error?.response?.data?.error?.name === "TokenExpiredError") {
          localStorage.removeItem("authToken");
          localStorage.removeItem("auth_id");
        }
      });
  };
};
export const logout = () => {
  return (dispatch) => {
    dispatch(getUserData(false));
    localStorage.removeItem("auth_id");
    localStorage.removeItem("authToken");
    toast.success("user logout successfully");
  };
};
