import React from "react";
import Logo from "../../Assets/Images/gymstar-menu-logo.png";
import "./header.css";
export default function Header2({ text, ...props }) {
  return (
    <>
      <section className="inner-Header-2">
        <div>
          <h1>{text}</h1>
        </div>
      </section>
    </>
  );
}
