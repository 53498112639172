import React, { PureComponent } from "react";
import AppRouter from "./router";
class Router extends PureComponent {
  render() {
    console.log("Index");
    return <AppRouter />;
  }
}

export default Router;
