import react from "react";
export default function MaleForm(params) {
  return (
    <div className="MaleForm">
      <div className="Header-Text">
        <h4 style={{ color: "#404040" }}>BODY MEASUREMENTS</h4>
      </div>
      <div>
        <h4 style={{ color: "#404040" }}>TODAYS BODY MEASUREMENTS</h4>
        <div className="input-Type-parent">
          <label>Notes & Dates</label>
          <textarea
            name="NotesDates"
            cols="40"
            rows="1"
            class="wpcf7-form-control wpcf7-textarea wpcf7-validates-as-required"
            aria-required="true"
            aria-invalid="false"
            placeholder="Notes &amp; Dates"
          ></textarea>
        </div>
        <div className="input-Type-parent">
          <label>Neck - Inches or Centimeters</label>
          <input
            name="NotesDates"
            className="wpcf7-form-control wpcf7-textarea wpcf7-validates-as-required"
            aria-required="true"
            aria-invalid="false"
            placeholder="Neck - Inches or Centimeters"
          />
        </div>
        <div className="input-Type-parent">
          <label>Shoulders</label>
          <input
            name="NotesDates"
            className="wpcf7-form-control wpcf7-textarea wpcf7-validates-as-required"
            aria-required="true"
            aria-invalid="false"
            placeholder="Shoulders"
          />
        </div>
        <div className="input-Type-parent">
          <label>Upper Arm - Tricep</label>
          <input
            name="NotesDates"
            className="wpcf7-form-control wpcf7-textarea wpcf7-validates-as-required"
            aria-required="true"
            aria-invalid="false"
            placeholder="Upper Arm - Tricep"
          />
        </div>
        <div className="input-Type-parent">
          <label>Middle Arm - Bicep</label>
          <input
            name="NotesDates"
            className="wpcf7-form-control wpcf7-textarea wpcf7-validates-as-required"
            aria-required="true"
            aria-invalid="false"
            placeholder="Middle Arm - Bicep"
          />
        </div>
        <div className="input-Type-parent">
          <label>Forearm</label>
          <input
            name="NotesDates"
            className="wpcf7-form-control wpcf7-textarea wpcf7-validates-as-required"
            aria-required="true"
            aria-invalid="false"
            placeholder="ChestForearm"
          />
        </div>
        <div className="input-Type-parent">
          <label>Chest</label>
          <input
            name="NotesDates"
            className="wpcf7-form-control wpcf7-textarea wpcf7-validates-as-required"
            aria-required="true"
            aria-invalid="false"
            placeholder="Chest"
          />
        </div>
        <div className="input-Type-parent">
          <label>Underchest</label>
          <input
            name="NotesDates"
            className="wpcf7-form-control wpcf7-textarea wpcf7-validates-as-required"
            aria-required="true"
            aria-invalid="false"
            placeholder="Underchest"
          />
        </div>
        <div className="input-Type-parent">
          <label>Waist</label>
          <input
            name="NotesDates"
            className="wpcf7-form-control wpcf7-textarea wpcf7-validates-as-required"
            aria-required="true"
            aria-invalid="false"
            placeholder="Waist"
          />
        </div>
        <div className="input-Type-parent">
          <label>Abdomen</label>
          <input
            name="NotesDates"
            className="wpcf7-form-control wpcf7-textarea wpcf7-validates-as-required"
            aria-required="true"
            aria-invalid="false"
            placeholder="Abdomen"
          />
        </div>
        <div className="input-Type-parent">
          <label>Buttocks</label>
          <input
            name="NotesDates"
            className="wpcf7-form-control wpcf7-textarea wpcf7-validates-as-required"
            aria-required="true"
            aria-invalid="false"
            placeholder="Buttocks"
          />
        </div>
        <div className="input-Type-parent">
          <label>Leg Upper</label>
          <input
            name="NotesDates"
            className="wpcf7-form-control wpcf7-textarea wpcf7-validates-as-required"
            aria-required="true"
            aria-invalid="false"
            placeholder="Leg Upper"
          />
        </div>
        <div className="input-Type-parent">
          <label>Leg Middle</label>
          <input
            name="NotesDates"
            className="wpcf7-form-control wpcf7-textarea wpcf7-validates-as-required"
            aria-required="true"
            aria-invalid="false"
            placeholder="Leg Middle"
          />
        </div>
        <div className="input-Type-parent">
          <label>Leg Lower</label>
          <input
            name="NotesDates"
            className="wpcf7-form-control wpcf7-textarea wpcf7-validates-as-required"
            aria-required="true"
            aria-invalid="false"
            placeholder="Leg Lower"
          />
        </div>
        <div className="input-Type-parent">
          <label>Calves</label>
          <input
            name="NotesDates"
            className="wpcf7-form-control wpcf7-textarea wpcf7-validates-as-required"
            aria-required="true"
            aria-invalid="false"
            placeholder="Calves"
          />
        </div>
        <div className="input-Type-parent">
          <label>Ankle</label>
          <input
            name="NotesDates"
            className="wpcf7-form-control wpcf7-textarea wpcf7-validates-as-required"
            aria-required="true"
            aria-invalid="false"
            placeholder="Ankle"
          />
        </div>
        <h4 style={{ color: "#404040", marginTop: "10px" }}>
          BODY UPGRADE MEASUREMENTS
        </h4>
        <div className="input-Type-parent">
          <label>Notes & Dates</label>
          <textarea
            name="NotesDates"
            cols="40"
            rows="1"
            class="wpcf7-form-control wpcf7-textarea wpcf7-validates-as-required"
            aria-required="true"
            aria-invalid="false"
            placeholder="Notes &amp; Dates"
          ></textarea>
        </div>
        <div className="input-Type-parent">
          <label>Neck - Inches or Centimeters</label>
          <input
            name="NotesDates"
            className="wpcf7-form-control wpcf7-textarea wpcf7-validates-as-required"
            aria-required="true"
            aria-invalid="false"
            placeholder="Neck - Inches or Centimeters"
          />
        </div>
        <div className="input-Type-parent">
          <label>Shoulders</label>
          <input
            name="NotesDates"
            className="wpcf7-form-control wpcf7-textarea wpcf7-validates-as-required"
            aria-required="true"
            aria-invalid="false"
            placeholder="Shoulders"
          />
        </div>
        <div className="input-Type-parent">
          <label>Upper Arm - Tricep</label>
          <input
            name="NotesDates"
            className="wpcf7-form-control wpcf7-textarea wpcf7-validates-as-required"
            aria-required="true"
            aria-invalid="false"
            placeholder="Upper Arm - Tricep"
          />
        </div>
        <div className="input-Type-parent">
          <label>Middle Arm - Bicep</label>
          <input
            name="NotesDates"
            className="wpcf7-form-control wpcf7-textarea wpcf7-validates-as-required"
            aria-required="true"
            aria-invalid="false"
            placeholder="Middle Arm - Bicep"
          />
        </div>
        <div className="input-Type-parent">
          <label>Forearm</label>
          <input
            name="NotesDates"
            className="wpcf7-form-control wpcf7-textarea wpcf7-validates-as-required"
            aria-required="true"
            aria-invalid="false"
            placeholder="ChestForearm"
          />
        </div>
        <div className="input-Type-parent">
          <label>Chest</label>
          <input
            name="NotesDates"
            className="wpcf7-form-control wpcf7-textarea wpcf7-validates-as-required"
            aria-required="true"
            aria-invalid="false"
            placeholder="Chest"
          />
        </div>
        <div className="input-Type-parent">
          <label>Underchest</label>
          <input
            name="NotesDates"
            className="wpcf7-form-control wpcf7-textarea wpcf7-validates-as-required"
            aria-required="true"
            aria-invalid="false"
            placeholder="Underchest"
          />
        </div>
        <div className="input-Type-parent">
          <label>Waist</label>
          <input
            name="NotesDates"
            className="wpcf7-form-control wpcf7-textarea wpcf7-validates-as-required"
            aria-required="true"
            aria-invalid="false"
            placeholder="Waist"
          />
        </div>
        <div className="input-Type-parent">
          <label>Abdomen</label>
          <input
            name="NotesDates"
            className="wpcf7-form-control wpcf7-textarea wpcf7-validates-as-required"
            aria-required="true"
            aria-invalid="false"
            placeholder="Abdomen"
          />
        </div>
        <div className="input-Type-parent">
          <label>Buttocks</label>
          <input
            name="NotesDates"
            className="wpcf7-form-control wpcf7-textarea wpcf7-validates-as-required"
            aria-required="true"
            aria-invalid="false"
            placeholder="Buttocks"
          />
        </div>
        <div className="input-Type-parent">
          <label>Leg Upper</label>
          <input
            name="NotesDates"
            className="wpcf7-form-control wpcf7-textarea wpcf7-validates-as-required"
            aria-required="true"
            aria-invalid="false"
            placeholder="Leg Upper"
          />
        </div>
        <div className="input-Type-parent">
          <label>Leg Middle</label>
          <input
            name="NotesDates"
            className="wpcf7-form-control wpcf7-textarea wpcf7-validates-as-required"
            aria-required="true"
            aria-invalid="false"
            placeholder="Leg Middle"
          />
        </div>
        <div className="input-Type-parent">
          <label>Leg Lower</label>
          <input
            name="NotesDates"
            className="wpcf7-form-control wpcf7-textarea wpcf7-validates-as-required"
            aria-required="true"
            aria-invalid="false"
            placeholder="Leg Lower"
          />
        </div>
        <div className="input-Type-parent">
          <label>Calves</label>
          <input
            name="NotesDates"
            className="wpcf7-form-control wpcf7-textarea wpcf7-validates-as-required"
            aria-required="true"
            aria-invalid="false"
            placeholder="Calves"
          />
        </div>
        <div className="input-Type-parent">
          <label>Ankle</label>
          <input
            name="NotesDates"
            className="wpcf7-form-control wpcf7-textarea wpcf7-validates-as-required"
            aria-required="true"
            aria-invalid="false"
            placeholder="Ankle"
          />
        </div>
        <div className="SAVE-TODAY">
          <button>SAVE TODAY`s</button>
          <button>Save UPDATE.</button>
        </div>
        <p className="Re-measure">
          Re-measure yourself every couple of weeks and record you measurements,
          so that you can chart your progress. This is a great motivator! It can
          be discouraging to see your measurements at the start of the program,
          but once you begin to see results, the feeling of accomplishment is
          incredible. Even if you have already commenced your program, it is not
          too late to get measuring (body fat percentage or body measurements),
          as it is wonderful and rewarding to watch your body continue to
          change.
        </p>
      </div>
    </div>
  );
}
