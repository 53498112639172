import React from "react";
import "./footer.css";
import Image from "../../Assets/Images/gymstar-footer-logo-1.png";
export default function Foooter() {
  return (
    <footer className="Footer">
      <div className="row">
        <div className="col-md-3 col-sm-6 ImgParent">
          <img src={Image} width="200" />
        </div>
        <div className="row col-md-9">
          <div className="col-md-3 col-sm-6">
            <div className="Heading">NAVIGATION</div>
            <ul className="list">
              <li>Edit My Account</li>
              <li>Up Grade Policy</li>
              <li>My Body Profile</li>
              <li>WorkOut Log</li>
              <li>Station Log</li>
            </ul>
          </div>
          <div className="col-md-3 col-sm-6">
            <div className="Heading">RESOURCES</div>
            <ul className="list">
              <li>About GYMstar</li>
              <li>About GYMTime</li>
              <li>What’s New?</li>
              <li>Instructions</li>
              <li>Help</li>
            </ul>
          </div>
          <div className="col-md-3 col-sm-6">
            <div className="Heading"> NECESSITIES</div>
            <ul className="list">
              <li>Payments</li>
              <li>Associate Agreement</li>
              <li>IP Policy</li>
              <li>Privacy Policy</li>
              <li>Terms of Service</li>
            </ul>
          </div>
          <div className="col-md-3 col-sm-6">
            <div></div>
            <p className="Heading">NEED HELP</p>
            {/* <a>Management@GYMStarPro.com</a> */}
          </div>
        </div>
      </div>
    </footer>
  );
}
