import React, { useState } from "react";
import Header2 from "../../Components/Header/header2";
import Img from "../../Assets/Images/jym.png";
import "./App.css";
import Table from "./Table";
import Forms from "./Form";
import Header from "./Header";
import { useEffect } from "react";
import { connect, useSelector } from "react-redux";
import {
  addWorkoutfn,
  getstationdatafn,
  getWorkoutData,
} from "../../Store/actions/station";
import { toast } from "react-toastify";
import moment from "moment";
function Routine(props) {
  let url = new URL(window.location);
  let routineName = url.searchParams.get("mustleType");
  let mustleGroup = url.searchParams.get("mustleGroup");
  // let id = url.searchParams.get("id");
  // let stationNumber = url.searchParams.get("stationNumber");
  const user = useSelector((store) => store?.auth?.user[0]);
  const fromtoData = useSelector((store) => store?.station?.fromtoData);
  const [stationData, setStationData] = useState(false);
  const [fromData, setFromData] = useState({});
  const [toData, setToData] = useState({});
  const [stationNumber, setstationNumber] = useState();
  const [Object, setObject] = useState({
    Reps: "",
    Weight: "",
    time: "",
  });

  {
    console.log(mustleGroup);
  }

  const [Open, setOpen] = useState(false);
  useEffect(() => {
    let from = {
      weight: 0,
      set: 0,
      total: 0,
      newWeight: 0,
      newReps: 0,
      gymStarTotal: 0,
      reps: 0,
    };
    let to = {
      total: 0,
      weight: 0,
      reps: 0,
      newWeight: 0,
      gymStarTotal: 0,
      newReps: 0,
      set: 0,
    };
    if (fromtoData?.from?.length) {
      fromtoData?.from?.map((e) => {
        from.set = Number(e.set);
        from.reps = Number(e.reps) + from.reps;
        from.weight = Number(e.weight) + from.weight;
        from.newWeight = Number(e.weight);
        from.newReps = Number(e.reps);
        from.gymStarTotal = Number(e?.gymStarTotal);
        // from.total = Number(from.weight) * Number(from.reps);
        // from.total =
        //   Number(from.weight) * Number(from.reps) +
        //   Number(e.reps) * Number(e.weight);
        from.total = Number(e.weight) * Number(e.reps) + from.total;
      });
    }
    if (fromtoData?.to?.length) {
      fromtoData?.to?.map((e) => {
        // console.log(e);
        // console.log(
        //   Number(to.weight) * Number(to.weight) +
        //     Number(e.reps) * Number(e.weight)
        // );
        to.newWeight = Number(e.weight) * Number(e.reps);
        to.newReps = Number(e.reps);
        to.set = Number(e.set);
        to.gymStarTotal = Number(e?.gymStarTotal);
        to.reps = Number(e.reps) + to.reps;
        to.weight = Number(e.weight) + to.weight;
        to.total = Number(e.weight) * Number(e.reps) + to.total;
      });
    }
    setFromData(from);
    setToData(to);
    // console.log(from, "from");
    // console.log(to, "to");
  }, [fromtoData]);
  // useEffect(() => {
  //   if (user?._id) {
  //     props.getstation(id, setStationData);
  //     // props.getWorkoutData(
  //     //   user?._id,
  //     //   stationNumber
  //     // );
  //   }
  // }, [user?._id]);
  useEffect(() => {
    if (stationNumber) {
      props.getWorkoutData(
        {
          userId: user?._id,
        },
        Number(stationNumber),
        mustleGroup
      );
    }
  }, [stationData, stationNumber]);
  const SubmitFn = (e) => {
    e.preventDefault();
    // if (toData?.set) {
    if (localStorage.getItem("auth_id") && localStorage.getItem("authToken")) {
      if (
        Object.Reps !== "" &&
        Object.Weight !== "" &&
        mustleGroup &&
        routineName
      ) {
        let arr = {
          mustleType: routineName,
          mustleGroup: mustleGroup,
          set: toData?.set ? toData?.set + 1 : 1,
          userId: user?._id,
          weight: Object.Weight,
          stationNumber: stationNumber,
          reps: Object.Reps,
          timer: moment().format("MMMM Do YYYY"),
          date: moment().format("MMMM Do YYYY h:mm:ss a"),
        };
        props.addWorkoutfn(arr, setObject);
      } else {
        console.log("Please fill all fields");
        toast.error("Please fill all fields");
      }
    } else {
      toast.error("Please login to continue");
    }
    // setObjectData(Object);
  };
  return (
    <>
      <Header2 text={routineName} />
      <div className="container Routine">
        <div
          className="Inner-routine"
          style={{ backgroundImage: `url(${Img})` }}
        >
          <Header />
          <Forms
            stationNumber={stationNumber}
            setObject={setObject}
            setstationNumber={setstationNumber}
            stationData={stationData}
            Object={Object}
            toData={toData}
            mustleGroup={mustleGroup}
            SubmitFn={SubmitFn}
          />
          <div className="Table">
            <Table toData={toData} fromData={fromData} />
          </div>
        </div>
      </div>
    </>
  );
}
const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  getWorkoutData: (id, stationNumber, mustleGroup) =>
    dispatch(getWorkoutData(id, stationNumber, mustleGroup)),
  addWorkoutfn: (id, setObject) => dispatch(addWorkoutfn(id, setObject)),
  getstation: (id, setStataionData) =>
    dispatch(getstationdatafn(id, setStataionData)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Routine);
