import React, { useState } from "react";
import { useEffect } from "react";
import { connect, useSelector } from "react-redux";
import Header2 from "../../Components/Header/header2";
import {
  createStationFn,
  deleteStation,
  getStationFn,
} from "../../Store/actions/station";
import { Muscletype } from "../../Store/constant";
import CreateStationModal from "./createStationModal";
import StationCard from "./Table";
import "./workoutStation.css";

function WorkoutStation({ ...props }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [mustleType, setMustleType] = useState("");
  const [routineName, setRoutineName] = useState("");
  const [stationNo, setStationNo] = useState("");
  const [csvSave, setCsvSave] = useState([]);
  const [files, setFiles] = useState("");
  const [loader, setLoader] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  const user = useSelector((store) => store?.auth?.user[0]);
  const stationData = useSelector((store) => store?.station?.workoutData);
  const fileReader = new FileReader();

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  let MuscleGroup = [
    "Abdominal",
    "arm",
    "Back",
    "Chest",
    "Extra Muscle",
    "Forearms",
    "Legs (Lower) Calves",
    "Legs (Upper)",
    "Shoulder",
    "Train",
    "CrossTrain",
  ];

  useEffect(() => {
    if (user?._id) {
      props.getStationFn(user?._id, stationNo, mustleType, routineName);
    }
  }, [user]);

  useEffect(() => {
    if (user?._id) {
      props.getStationFn(user?._id, stationNo, mustleType, routineName);
    }
  }, [stationNo, mustleType, routineName]);

  const reset = () => {
    setStationNo("");
    setRoutineName("");
    setMustleType("");
  };

  const csvFileToArray = (string) => {
    const csvHeader = string.slice(0, string.indexOf("\n")).split(",");
    const csvRows = string.slice(string.indexOf("\n") + 1).split("\n");

    const array = csvRows.map((i) => {
      const values = i.split(",");
      const obj = csvHeader.reduce((object, header, index) => {
        object[JSON.parse(header)] = JSON.parse(values[index]);
        return object;
      }, {});
      return obj;
    });
    setCsvSave(array);
  };

  const handleDelete = () => {
    // let arr = [];
    // stationData?.map((e, i) => arr.push(e?._id));
    props?.deleteStation(user?._id, selectedItems);
  };

  useEffect(() => {
    if (csvSave?.length) {
      console.log(csvSave[0], "csvSave");
      let data = csvSave;
      let object = {
        tools: data[0].Tools,
        workoutRoutineName: data[0]?.WorkoutRoutineName,
        workoutDiscloser: data[0]?.MuscleworkoutDisclosure,
        mustleType: data[0]?.mustleType,
        stationNumber: data[0]?.stationNumber,
        // workoutRoutineNumber: data[0]?.WorkoutRoutineNumber.split(","),
        workoutTitle: data[0]?.Title,
        workoutOrder: data[0]?.sheduleOrder,
        workoutDays: [data[0]?.sheduleDay],
        userId: user?._id,
      };
      console.log(data[0]?.Tools, "createStation");
      console.log(object, "createStation(");
      props.createStation(object, setLoader);
      setFiles("");
      document.getElementById("uploadCaptureInputFile").value = "";
    }
  }, [csvSave]);

  useEffect(() => {
    if (files !== "" || files) {
      const fileReader = new FileReader();
      fileReader.onload = function (event) {
        const csvOutput = event.target.result;
        csvFileToArray(csvOutput);
        // console.log(csvOutput);
      };
      fileReader.readAsText(files);
      // console.log(fileReader.readAsText(files));
    }
  }, [files]);

  const handleItemSelection = (itemId, isSelected) => {
    if (isSelected) {
      setSelectedItems([...selectedItems, itemId]);
    } else {
      setSelectedItems(selectedItems.filter((id) => id !== itemId));
    }
  };

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedItems([]);
    } else {
      const allItemIds = stationData.map((item) => item._id);
      setSelectedItems(allItemIds);
    }
    setSelectAll(!selectAll);
  };

  useEffect(() => {
    console.log(selectedItems);
  }, [selectedItems]);

  return (
    <>
      <Header2 text={"Station Logs"} />
      <div className="Muscle-Header">
        <div className="Head1">Professional Calculator Results</div>
        <button className="Button-Blue">WORKOUT LOG VIDEO TRAINING</button>
        <div className="Head1">Station Logs</div>
      </div>
      <div
        className="Muscle-Body res-Muscle-Body"
        style={{ marginBottom: "20px" }}
      >
        <div className="Select-Parent">
          <select
            className="Select"
            value={mustleType}
            placeholder="Muscle Type"
            onChange={(ev) => setMustleType(ev.target.value)}
          >
            <option value={""}>Muscle Type</option>
            {Muscletype?.map((e) => (
              <option value={e}>{e}</option>
            ))}
          </select>
          <select
            className="Select"
            value={routineName}
            placeholder="Routine"
            onChange={(ev) => setRoutineName(ev.target.value)}
          >
            <option value={""}>Routine</option>
            {user?.allWorkoutRoutineName?.map((e) => (
              <option value={e}>{e}</option>
            ))}
          </select>
          <select
            className="Select"
            placeholder="station Number"
            value={stationNo}
            onChange={(ev) => setStationNo(ev.target.value)}
          >
            <option value={""}>station Number</option>
            {user?.allStationNumbers.map((e) => (
              <option value={e}>{e}</option>
            ))}
          </select>
          <button className="Button-Blue margin" onClick={reset}>
            RESET
          </button>
        </div>
        <div className="Station">
          <div>
            <input
              type={"file"}
              typeof={"csv"}
              accept=".csv"
              id="uploadCaptureInputFile"
              onChange={(e) => {
                setFiles(e.target.files[0]);
              }}
            />
          </div>
          <button className="Station-btn" onClick={showModal}>
            create Station
          </button>
        </div>
      </div>
      <div className="Delete">
        <button className="Button-Blue margin" onClick={handleSelectAll}>
          Select All
        </button>
        <button className="Button-Blue margin" onClick={handleDelete}>
          Delete All Selected
        </button>
      </div>
      <div className="station-data">
        {stationData?.map((e, i) => {
          return (
            <StationCard
              data={e}
              key={i}
              onSelectionChange={handleItemSelection}
              selectAll={selectAll}
            />
          );
        })}
      </div>
      <CreateStationModal
        handleCancel={handleCancel}
        handleOk={handleOk}
        showModal={showModal}
        isModalOpen={isModalOpen}
      />
    </>
  );
}
const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  getStationFn: (id, stationNo, mustleType, routineName) =>
    dispatch(getStationFn(id, stationNo, mustleType, routineName)),
  createStation: (data, setLoader) =>
    dispatch(createStationFn(data, setLoader)),
  deleteStation: (id, arr) => dispatch(deleteStation(id, arr)),
});

export default connect(mapStateToProps, mapDispatchToProps)(WorkoutStation);
