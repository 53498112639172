import { USER_DATA } from "../actions/auth";
import { GET_FILTERED_WORKOUT, STATION_DATA, STATION_FROM_DATA, STATION_WORKOUT_RECORD } from "../actions/station";

export const defaultState = {
  workoutData: [],
  filteredWorkoutData: [],
  fromtoData: [],
  workoutRecord: [],
};
const states = (state = defaultState, action) => {
  switch (action.type) {
    case STATION_DATA:
      console.log(action.payload);
      return {
        ...state,
        workoutData: action.payload,
      };
    case GET_FILTERED_WORKOUT:
      return {
        ...state,
        filteredWorkoutData: action.payload,
      };
    case STATION_WORKOUT_RECORD:
      return {
        ...state,
        workoutRecord: action.payload,
      };
    case STATION_FROM_DATA:
      return {
        ...state,
        fromtoData: action.payload,
      };
    default:
      return state;
  }
};
export default states;
