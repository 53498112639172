import React from "react";
import Input from "../../Components/input";
export default function ({ signupobj, password, setPassword, setSignupobj }) {
  return (
    <div className="Login flexstart">
      <div>
        <h3>ACCOUNT INFORMATION</h3>
        <span>
          {" "}
          ALREADY HAVE AN ACCOUNT? <a>LOG IN</a>
        </span>{" "}
        HERE
      </div>
      <form className="Inner-Login" id="SubmitForm">
        <label>Name</label>
        <Input
          className="Input_type"
          required
          placeholder="Name"
          value={signupobj?.userName}
          onChange={(e) => setSignupobj({ ...signupobj, userName: e.target.value })}
        />
        <label>Email</label>
        <Input
          className="Input_type"
          required
          type="email"
          placeholder="Email"
          value={signupobj?.email}
          onChange={(e) =>
            setSignupobj({ ...signupobj, email: e.target.value })
          }
        />
        <label>Password</label>
        <Input
          className="Input_type"
          required
          type="password"
          placeholder="Password"
          value={signupobj?.password}
          onChange={(e) =>
            setSignupobj({ ...signupobj, password: e.target.value })
          }
        />
        <label>Confirm Password</label>
        <Input
          className="Input_type"
          required
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </form>
    </div>
  );
}
