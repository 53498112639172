import React, { useState } from "react";
import Header2 from "../../Components/Header/header2";
import Input from "../../Components/input";
import "./MembershipLevels.css";
import MembershipTable from "./MembershipTable";
export default function MembershipLevels() {
  const [Object, setObject] = useState({});
  const [password, setPassword] = useState({});
  return (
    <>
      <Header2 text={"MEMBERSHIP LEVELS"} />
      <div className="MembershipLevels container">
        <MembershipTable />
      </div>
    </>
  );
}
