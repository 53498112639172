import React from "react";
export default function FreeInfo() {
  return (
    <div className="FreeInfo">
      <div className="MEMBERSHIP-Level">
        <h3>MEMBERSHIP LEVEL</h3>
        <a href="">CHANGE</a>
      </div>
      <p>
        You have selected the{" "}
        <strong className="color"> Free Membership</strong> level.
      </p>

      <p>
        The price for membership is <strong className="color">$0.00</strong>{" "}
        now.
      </p>

      <p>Membership expires after 3 Weeks.</p>
      <p>
        Do you have a discount code? Click here to enter your discount code.
      </p>
    </div>
  );
}
