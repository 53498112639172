import { USER_DATA } from "../actions/auth";

export const defaultState = {
  user: false,
};
const states = (state = defaultState, action) => {
  switch (action.type) {
    case USER_DATA:
      console.log(action.payload);
      return {
        ...state,
        user: action.payload,
      };
    default:
      return state;
  }
};
export default states;
