import { Dropdown, Menu } from "antd";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../../Assets/Images/gymstar-menu-logo.png";
import { logout } from "../../Store/actions/auth";
import "./header.css";
import NavbarModal from "./navbarModal";
function Header(props) {
  const menu = (
    <Menu>
      <Menu.Item>
        <Link to="/MembershipLevels/checkout?type=Free">FREE REGISTRATION</Link>
      </Menu.Item>
      <Menu.Item>
        <Link to="/MembershipLevels/checkout?type=Gold">GOLD MEMBERSHIP</Link>
      </Menu.Item>
      <Menu.Item>
        <Link to="/MembershipLevels/checkout?type=Silver">
          SILVER MEMBERSHIP
        </Link>
      </Menu.Item>
      <Menu.Item>
        <Link to="/MembershipLevels/checkout?type=Bronze">
          BRONZE MEMBERSHIP
        </Link>
      </Menu.Item>
    </Menu>
  );
  const menu2 = (
    <Menu>
      <Menu.Item>GYMSTAR CALCULATOR INSTRUCTIONS</Menu.Item>
      <Menu.Item>GYMSTAR STATION LOG TRAINING</Menu.Item>
      <Menu.Item>GYMSTAR WORKOUT RECORDS TRAINING</Menu.Item>
    </Menu>
  );
  const menu3 = (
    <Menu>
      <Menu.Item>
        <Link to="/MuscleRecord">WORKOUT RECORD</Link>
      </Menu.Item>
      <Menu.Item>
        <Link to="/workoutStation">STATION LOGS</Link>
      </Menu.Item>
      <Menu.Item>
        <Link to="/Workout">WORKOUT</Link>
      </Menu.Item>
    </Menu>
  );
  const menu4 = (
    <Menu>
      <Menu.Item>
        <Link to="/bodyprofileFemale">Ladies Body Profile</Link>
      </Menu.Item>
      <Menu.Item>
        <Link to="/bodyprofilemale">Male Body Profile</Link>
      </Menu.Item>
    </Menu>
  );
  const menu5 = (
    <Menu>
      <Menu.Item>
        <Link
          onClick={(e) => {
            e.preventDefault();
            props.logout(navigation);
          }}
        >
          logout
        </Link>
      </Menu.Item>
    </Menu>
  );
  const navigation = useNavigate();
  const [userData, setUserData] = useState(false);
  const user = useSelector((store) => store?.auth?.user[0]);
  console.log(user);
  useEffect(() => {
    // console.log(user);
    setUserData(user);
  }, [user]);
  return (
    <header>
      <div className="Inner-header">
        <Link to="/">
          <img src={Logo} />
        </Link>
        <div className="Inner-Menu mediaRes">
          <Dropdown overlay={menu}>
            <Link>MEMBERSHIP</Link>
          </Dropdown>
          <Dropdown overlay={menu2}>
            <Link>INSTRUCTIONS</Link>
          </Dropdown>
          <Dropdown overlay={menu3}>
            <Link to="/Workout">WORKOUT</Link>
          </Dropdown>
          <Dropdown overlay={menu4}>
            <Link>Profile</Link>
          </Dropdown>
          {userData && (
            <Dropdown overlay={menu5}>
              <Link>hello,{userData?.userName}</Link>
            </Dropdown>
          )}
        </div>
        {!userData && (
          <div className="Header-child mediaRes">
            <Link className="button-Login" to="/Login">
              Login
            </Link>
          </div>
        )}
        <div className="NavbarModal">
          <NavbarModal
            menu4={menu4}
            menu3={menu3}
            menu2={menu2}
            menu5={menu5}
            user={userData}
            menu={menu}
            {...props}
          />
        </div>
      </div>
    </header>
  );
}
const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(logout()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
