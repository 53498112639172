export let BACKEND_URL = "https://gymstar-backend.vercel.app";
// export let BACKEND_URL = "http://localhost:5000";

export let Muscletype = [
  "Abdominal",
  "Triceps",
  "Biceps",
  "Back",
  "Chest Upper",
  "Chest Lower",
  "XTraM",
  "Forearms",
  "Lower Legs Calves",
  "Legs Front",
  "Legs Back",
  "Gluteus Maxim(Butt)",
  "Deltoids Back",
  "Deltoids Front",
  "Gluteus Maxim(Butt)",
  "CrossTrain",
];

export let gymTools = [
  "pushUps",
  "sitUps",
  "Planks",
  "Dumbill",
  "bar",
  "wbar",
  "bar",
  "cable",
  "machine",
  "Bench",
  "incbel",
  "tredmill",
];
