import React from "react";
import Input from "../../Components/input";
import Submit from "../../Assets/Images/ra.png";

export default function Forms({
  setObject,
  setstationNumber,
  mustleGroup,
  Object,
  SubmitFn,
  stationData,
  stationNumber,
  toData,
}) {
  return (
    <form onSubmit={SubmitFn} className="Form">
      <div className="Form-Label">
        <label>Muscle Type</label>
        <Input
          className="Input"
          required
          disabled
          type="text"
          placeholder="Muscle Type"
          value={mustleGroup}
          // onChange={(e) => setObject({ ...Object, mustleType: e.target.value })}
        />
        <label>Station No</label>
        <Input
          className="Input"
          required
          type="number"
          placeholder="Station No"
          value={stationNumber}
          onChange={(e) => setstationNumber(e.target.value)}
        />
      </div>
      <div className="Form-Label">
        <label>Set</label>
        <div className="Cal">{toData?.set + 1}</div>
        <label>Weight</label>
        <Input
          className="Input Input-width"
          required
          type="number"
          placeholder="0"
          value={Object?.Weight}
          onChange={(e) => setObject({ ...Object, Weight: e.target.value })}
        />
        {/* <label>Time Taken(in min)</label>
        <Input
          className="Input Input-width"
          required
          type="number"
          placeholder="0.0"
          value={Object?.time}
          onChange={(e) => setObject({ ...Object, time: e.target.value })}
        /> */}
        <label>Reps</label>
        <Input
          className="Input Input-width"
          placeholder="0"
          type="number"
          required
          value={Object?.Reps}
          onChange={(e) => setObject({ ...Object, Reps: e.target.value })}
        />
        <div className="Cal">Total</div>
      </div>
      <div className="Form-Label">
        <button className="btn-submit" type="submit">
          <img src={Submit} />
        </button>
        <div className="Cal">Last Results</div>
      </div>
      {/* <button type="submit">Total</button> */}
    </form>
  );
}
