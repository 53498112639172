import React from "react";
export default function UserAgreeMent() {
  return (
    <div className="UserAgreeMent">
      <h4>
        MEMBERSHIP AGREEMENT - NDA (SOFTWARE LICENSE TERMS AND CONDITIONS)
      </h4>
      <div className="UserAgreeMent-Body">
        <div className="inner-UserAgreeMent-Body">
          <h4 style={{ textAlign: "center" }}>
            End-User GYMStar Club Membership &amp; License Agreement (EULA)
          </h4>
          <p>
            <strong>
              <u>&nbsp;</u>
            </strong>
            <strong>
              <u>SOFTWARE LICENSE TERMS AND CONDITIONS</u>
            </strong>
          </p>
          <p>
            BY INSTALLING OR USING THE LICENSED SOFTWARE FROM GYMSTAR
            CALCULATORS LTD. (“GYMSTAR CALCULATORS LTD. ”), THE INDIVIDUAL IF
            ACTING ON BEHALF OF HIMSELF OR HERSELF (“INDIVIDUAL CUSTOMER“) OR
            THE INDIVIDUAL WHO IS ACTING ON BEHALF OF AN EDUCATIONAL OR
            NONPROFIT INSTITUTION, GOVERNMENTAL AGENCY, OR OTHER (“ENTITY
            CUSTOMER”, THE INDIVIDUAL CUSTOMER AND ENTITY CUSTOMER TOGETHER ARE
            ”CUSTOMER”) IS AGREEING TO BE BOUND BY THIS SOFTWARE LICENSE &amp;
            GYMSTAR CLUB MEMBERSHIP AGREEMENT (“AGREEMENT”).
          </p>
          <p>
            By using or visiting the GYMStarPro.com Website and GYMStar Smart
            Watch App you acknowledge that you understand, agree, accept, and
            are able to accept, the Terms of Use. If you do not accept or
            understand this Terms of Use, you should not use or visit the
            GYMStarPro.com &nbsp;Website or Smart Watch App. Certain portions of
            the GYMStarPro Website, as well as promotions, contests or
            sweepstakes may have separate registration procedures, terms and
            conditions, services agreements and/or similar legal agreements
            which are also subject to subsequent change, modification or
            revocation under their respective terms. Unless explicitly stated
            otherwise, all new or modified versions of the GYMStarPro Website
            and Smart Watch App will also be subject to this Terms of Use.
          </p>
          <ol start="2">
            <li>
              <strong>
                <u>MEMBERSHIP AGREEMENT</u>
              </strong>
            </li>
          </ol>
          <p>
            <strong>&nbsp;</strong>This Membership Agreement (the “Agreement”)
            is made between GYMStar Club here after referred to as (the “Club”),
            a Nevada organization, and the undersigned member (the “Member” here
            after referred to as “Customer”). This Agreement is made because
            Customer wishes to receive benefits from the Club and Club wishes to
            provide GYMStar Calculator software to enhance the customer’s
            endeavors for better health through maintaining records of his or
            her exercise progress. The “effective date for this agreement is the
            day the customer installs the software.&nbsp; Therefore the
            following agree as per this “Agreement”.
          </p>
          <ol start="3">
            <li>
              <strong>
                <u>DEFINITIONS</u>
              </strong>
            </li>
          </ol>
          <p>
            <strong>&nbsp;</strong>
            <strong>“GYMStar Club”</strong> organization dedicated to outlining
            the development of GYMStar Calculator Fitness, GYMStar Bronze,
            GYMStar Silver, GYMStar Gold, and GYMStar Platinum Professional
            Calculators.&nbsp; In charge of bring to the Customer upgrades and
            information for the purpose of managing a successful calculation of
            weight lifting, training drills, cardio workouts, and cross
            training.&nbsp; In addition to this is providing communications
            about athletic events and advertising.
          </p>
          <p>
            <strong>“GYMStar Calculators Ltd.”</strong> a Nevada Corporation
            managing the sale and licensing of distributors to market and sale
            the GYMStar Series of Calculators, marketing and advertising.
          </p>
          <p>
            “<strong>Activation Key</strong>” means, collectively, the specific
            Serial Number, code, and authorization for each copy of the Licensed
            Software issued by GYMStar Calculators Ltd.to Customer.
          </p>
          <p>
            <strong>“Electronic Communications” </strong>by visiting the
            GYMStarPro.com Website and providing us with your contact
            information, you explicitly consent to receive communications from
            us. If you would not like to receive such communications, please do
            not purchase GYMStar Club Membership.
          </p>
          <p>
            <strong>“Third Party Links”</strong> we may provide hyperlinks to
            other websites over which we have no control. We are not responsible
            for the availability of such external sites or resources and will
            not be responsible or liable for any content, advertising, products,
            or other materials on or available from such third-party websites or
            resources.
          </p>
          <p>
            “<strong>Affiliates</strong>” or “<strong>Affiliate</strong>” means
            an entity, institution, or organization that controls, is controlled
            by, or is under common control with another entity, institution, or
            organization, with at least majority ownership.
          </p>
          <p>
            “<strong>Authorized Reseller</strong>” means an authorized
            distributor, authorized reseller, or dealer of the Licensed
            Software.
          </p>
          <p>
            “<strong>Authorized User</strong>” means an employee, contractor,
            registered student, research assistant, or agent of Entity Customer
            authorized by GYMStar Calculators Ltd. to use the Licensed Software.
          </p>
          <p>
            “<strong>Concurrent Authorized Users</strong>” means Authorized
            Users who use the software at the same time in accordance with the
            terms of this Agreement.
          </p>
          <p>
            “<strong>Confidential Information</strong>” has the meaning set
            forth in Section 7 of this Agreement.
          </p>
          <p>
            “<strong>Customer Files</strong>” means Customers Files in which the
            customer’s logged information into LivRight database.
          </p>
          <p>
            <strong>
              “Ownership of Customers Logged Information and Workout Database”
            </strong>{" "}
            is owned by GYMStar Calculators Ltd. and which are derivative works
            of the GYMStar Calculators Ltd.Enhancements.
          </p>
          <p>
            “<strong>Documentation</strong>” means the user manuals and
            supporting documentation in electronic form provided within the
            application under training and instructions.
          </p>
          <p>
            “<strong>Club Membership Fee</strong>” means the applicable fee for
            which Customer Club Membership is activated with the Licensed
            Software.
          </p>
          <p>
            “<strong>Club Membership (Term) Period</strong>” means a perpetual
            term unless (a) terminated as provided below or (b) a specific fixed
            term is otherwise set forth in the License and Activation Key.
          </p>
          <p>
            “<strong>Licensed Software</strong>” means the specific software
            licensed to Customer under the terms of this Agreement (as specified
            in the License and Activation Key issued to Customer), including any
            Updates and Upgrades thereto.
          </p>
          <p>
            “<strong>Serial Number</strong>” means a set of unique characters
            associated with a specific copy of the Licensed Software issued by
            GYMStar Calculators Ltd. to Customer (based on the specific
            configuration and release of the Licensed Software and the license
            type, license term, and/or number of Concurrent Authorized Users).
          </p>
          <p>
            “<strong>GYMStar Calculators Ltd.Enhancements</strong>” means
            providing information to Customer about athletic events and services
            including advertising containing health and supplements to improve
            performance.
          </p>
          <p>
            “<strong>GYMStar Calculators Ltd. Website</strong>” means{" "}
            <a href="http://gymtime.me">www.GYMStarpro.com</a>.
          </p>
          <p>
            “<strong>GYMStar Files</strong>” are files with file extensions of
            .pdf, xlxs, doc, or data base style. These files may be modified and
            distributed by the Customer pursuant to the terms and conditions of
            this license.
          </p>
          <p>
            “<strong>Update</strong>” means a revision to the Licensed Software
            or patch that improves the functionality of the Licensed Software,
            and may contain new features or enhancements, which is not an
            Upgrade.
          </p>
          <p>
            “<strong>Upgrade</strong>” means a subsequent version of the
            Licensed Software that GYMStar Calculators Ltd. designates as a new
            release and makes generally commercially available or a different
            flavor of the Licensed Software that GYMStar Calculators Ltd. makes
            generally commercially available.
          </p>
          <p>
            “<strong>Advertising</strong>” means that GYMStar Popups, banners,
            and postings on Customer GYMStar application and workout
            reports.&nbsp; By signing our agreement you agree to allow GYMStar
            Calculators Ltd. GYMStar Club to present to you promotions, contests
            or sweepstakes and vendors representing services or products for the
            purpose of engaging you into their products or services.
          </p>
          <p>
            <strong>“Trademarks and Copyrights” </strong>GYMStar Calculators™,
            the GYMStar Calculators™ logo, and all other related trademarks
            (including, without limitation, graphics, logos, page headers,
            button icons, scripts and service names) displayed on or within the
            GYMStarPro Website are trademarks of GYMStar Calculators and any use
            thereof must be approved by GYMStar Calculators Ltd. (“Marks”). The
            Marks may not be used in connection with any product or service
            without express written permission and all goodwill associated with
            the use of such Marks will inure to the benefit of their respective
            owners. All content residing on the GYMStarPro Website is the
            property of GYMStar Calculators Ltd. No underlying right, title or
            interest in or to any content will pass to you by virtue of
            accessing the GYMStarPro Website.
            <strong>&nbsp; </strong>
          </p>
          <ol start="4">
            <li>
              <strong>
                <u>
                  LICENSE AND ACTIVATION KEY, LICENSE GRANTS, THIRD-PARTY
                  RESTRICTIONS AND AUTHORIZATION TO DISTRIBUTE.
                </u>
              </strong>
            </li>
          </ol>
          <p>
            <strong>&nbsp;</strong>
            <u>4.1 License and Activation Key</u>
          </p>
          <p>
            GYMStar Calculators Ltd. shall issue Customer a “License and
            Activation Key” via email, or during installation after purchase The
            License and Activation Key is hereby incorporated by reference into
            this Agreement. GYMStar Calculators Ltd. shall issue to Customer a
            License and Activation Key for each copy of the Licensed Software.
            Customer is entirely responsible for any and all activities that
            occur under Customer's account and all charges incurred from use of
            the copy of the Licensed Software assigned by Serial Number to
            Customer (e.g., support, additional downloads, or license or
            subscription upgrade fee charges). The Licensed Software shall be
            deemed accepted upon the delivery of the Activation Key to Customer
            by GYMStar Calculators Ltd.
          </p>
          <p>
            <u>4.2 Licenses</u>
          </p>
          <p>
            <u></u>
            <u>(a) Single-User License Grant</u>
          </p>
          <p>
            This Section applies only to an Individual Customer whose License
            and Activation Key issued by GYMStar Calculators Ltd. specifies the
            “License Type” as “Single User”. A Single-User license is for the
            use of “GYMStar Bronze, GYMStar Silver, GYMStar Gold, and GYMStar
            Platinum Professional Calculator” Subject to the terms and
            conditions of this Agreement. GYMStar Calculators Ltd. grants to
            Customer a non-assignable, nontransferable license, without the
            right to sublicense, to use the Licensed Software solely for
            Customer’s internal application use, and solely by the Individual
            Customer.
          </p>
          <p>
            <u>4.3 License to GYMStar Calculators Ltd. Enhancements</u>
          </p>
          <p>
            THE GYMSTAR CALCULATORS LTD. ENHANCEMENTS ARE PROVIDED TO CUSTOMER
            ON AN ‘AS IS’ AND ‘WHERE IS’ BASIS AND WITHOUT WARRANTY OF ANY TYPE
            OR KIND. GYMSTAR CALCULATORS LTD. AND ITS THIRD-PARTY LICENSORS
            HEREBY EXPRESSLY DISCLAIM AND EXCLUDE ALL WARRANTIES AND CONDITIONS,
            WHETHER STATUTORY, EXPRESS, IMPLIED. OR OTHERWISE, WITH RESPECT TO
            THE GYMSTAR CALCULATORS LTD. ENHANCEMENTS, INCLUDING, BUT NOT
            LIMITED TO, ANY IMPLIED WARRANTY OF MERCHANTABILITY, FITNESS FOR A
            PARTICULAR PURPOSE, AND NON-INFRINGEMENT OF THIRD-PARTY RIGHTS.
          </p>
          <p>
            <u></u>
            <u>4.4 Restrictions.</u>
          </p>
          <p>
            Customer shall not, nor permit any person (including any Authorized
            User) to: (i) reverse engineer, reverse compile, decrypt,
            disassemble, or otherwise attempt to derive the source code of the
            Licensed Software (except to the extent that this restriction is
            expressly prohibited by law); (ii) modify, translate, or create
            derivative works of the Licensed Software; (iii) sublicense, resell,
            rent, lease, distribute, market, commercialize, or otherwise
            transfer rights or usage to the Licensed Software.&nbsp; Vendors or
            licensees may not remove, modify, or obscure any copyright notices
            or other proprietary notices or legends appearing on or in the
            Licensed Software, or any portion of marketing thereof.&nbsp;
            Vendors may not attach Trojan horse, or other harmful, disruptive,
            or unauthorized component; or (vii) embed the Licensed Software in
            any third-party applications.
          </p>
          <p>
            <u></u>
            <u>4.5 Ownership &amp; Copy write Disclosures</u>
          </p>
          <p>
            The Licensed Software, GYMStar Calculators Ltd.Enhancements, and
            Documentation contain copyrighted material and other proprietary
            material and information of GYMStar Calculators Ltd. and/or its
            licensors. GYMStar Calculators Ltd. and/or its licensors shall
            retain all right, title, and interest, including all intellectual
            property rights, in and to the Licensed Software, GYMStar
            Calculators Ltd. Enhancements, and Documentation. Customer will not
            remove, alter, or destroy any form of copyright notice, proprietary
            markings, or confidential legends placed upon or contained within
            the Licensed Software, GYMStar Calculators Ltd. Enhancements, or
            Documentation, or any component thereof.
          </p>
          <ol start="5">
            <li>
              <strong>
                <u>TECHNICAL SUPPORT AND UPGRADES AND UPDATES </u>
              </strong>
            </li>
          </ol>
          <p>
            <u>5.1 Technical Support.</u>
          </p>
          <p>
            GYMStar Calculators Ltd.&nbsp; agrees to provide Customer with
            technical support services which include periodic distribution of
            bug fixes and minor enhancements as Updates scheduled by GYMStar
            Calculators Ltd. . All registered users of the then-current release
            of GYMStar Calculator Series and the previous releases are eligible
            for free but limited technical support. Installation support
            inquiries by telephone will be accepted by GYMStar Calculators Ltd.
            during normal business hours. Technical support email inquiries are
            accepted at any time and will be answered during normal GYMStar
            Calculators Ltd. business hours. GYMStar Calculators Ltd. will
            attempt to respond to inquiries within the same business day.
          </p>
          <p>
            <u>5.2 Updates and Upgrades.</u>
          </p>
          <p>
            To receive and use an Upgrade, Customer club membership must be in
            current and agrees to GYMStar Calculators Ltd.’s standard terms and
            conditions governing the use of that Upgrade. If no such standard
            terms and conditions are stated by GYMStar Calculators Ltd. , the
            terms of this Agreement shall apply and the Upgrade shall be deemed
            Licensed Software. GYMStar Calculators Ltd. will issue a new
            Activation Key for the Upgrade. For a Single-User license, once
            GYMStar Calculators Ltd. issues the new Activation Key for an
            Upgrade version, Customer shall not be able to continue to use the
            prior version of the Licensed Software.
          </p>
          <ol start="6">
            <li>
              <strong>
                <u>LIMITED WARRANTY; WARRANTY DISCLAIMER</u>
              </strong>
            </li>
          </ol>
          <p>
            <u>&nbsp;</u>
            <u>6.1 Limited Media Warranty.</u>
          </p>
          <p>
            For thirty (30) days from the date of purchase, GYMStar Calculators
            Ltd. warrants that the media on which the Licensed Software, GYMStar
            Calculators Ltd. Enhancements, and Documentation is furnished shall
            be free from defects in material and faulty workmanship. Customer
            may return for replacement, without charge, any media that fails to
            meet this limited media warranty to GYMStar Calculators Ltd. or the
            dealer from whom the Licensed Software (and GYMStar Calculators Ltd.
            Enhancements) was purchased, as applicable, within the 30-day
            period. GYMStar Calculators Ltd. or the applicable dealer will not
            be responsible for replacing any media that contains defects due to
            Customer’s misuse. THE FOREGOING IS CUSTOMER'S SOLE REMEDY, AND
            GYMSTAR CALCULATORS LTD. 'S SOLE OBLIGATION, WITH RESPECT TO A
            BREACH OF THE LIMITED MEDIA WARRANTY.
          </p>
          <p>
            <u></u>
            <u>6.2 No Warranty</u>
          </p>
          <p>
            EXCEPT FOR THE WARRANTIES ABOVE, THE LICENSED SOFTWARE,
            DOCUMENTATION, AND SUPPORT SERVICES ARE PROVIDED TO CUSTOMER ON AN
            ‘AS IS’ AND ‘WHERE IS’ BASIS AND WITHOUT WARRANTY OF ANY TYPE OR
            KIND. GYMSTAR CALCULATORS LTD. HEREBY EXPRESSLY DISCLAIMS AND
            EXCLUDES ON BEHALF OF ITSELF AND ITS LICENSORS ALL WARRANTIES AND
            CONDITIONS, WHETHER STATUTORY, EXPRESS, IMPLIED, OR OTHERWISE, WITH
            RESPECT TO THE LICENSED SOFTWARE AND SUPPORT SERVICES, INCLUDING,
            BUT NOT LIMITED TO, ANY IMPLIED WARRANTY OF MERCHANTABILITY, FITNESS
            FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT OF THIRD PARTY
            RIGHTS.
          </p>
          <ol start="7">
            <li>
              <strong>
                <u>TERM AND TERMINATION.</u>
              </strong>
            </li>
          </ol>
          <p>
            <u>7.1 Term</u>
          </p>
          <p>
            Except for Customers with a fixed-term License Period, this
            Agreement shall commence on the Effective Date and shall continue in
            effect until terminated as set forth below. If the License Period is
            for a fixed term, this Agreement will commence on the Effective Date
            and shall continue until the earlier to occur of the expiration of
            the License Period or the termination of Club Membership Agreement
            as set forth above. If Customer purchases an Upgrade, the term of
            the Agreement shall be the specific term set forth in the new
            License and Club Membership with the Activation Key issued for the
            Upgrade.
          </p>
          <p>
            <u></u>
            <u>7.2 Termination</u>
          </p>
          <p>
            Customer may be terminated immediately without notice if Customer
            breaches any term of this Agreement, including, without limitation,
            breaching the scope of the license granted or confidentiality
            obligations under this Agreement.
          </p>
          <p>
            <u></u>
            <u>7.3 Effect of Expiration or Termination.</u>
          </p>
          <p>
            Upon expiration or termination of this Agreement and club
            membership, (i) the rights and licenses granted to Customer pursuant
            to this Agreement shall automatically and immediately terminate. In
            addition, for a fixed-term License Period, upon expiration of the
            License Period, the Activation Key will expire and the Licensed
            Software will cease to function. Sections 2.3, 2.6, 2.7, 2.8, 2.9,
            4.4, 5.3, 7, 8, 9, and 10 of this Agreement shall survive any
            expiration or termination of this Agreement.
          </p>
          <ol start="8">
            <li>
              <strong>
                <u>FEES AND PAYMENT </u>
              </strong>
            </li>
          </ol>
          <p>
            <u>8.1 Fees and Payment Terms.</u>
          </p>
          <p>
            Customer licenses the Licensed Software from GYMStar Calculators
            Ltd. . This Agreement is between Customer and GYMStar Calculators
            Ltd. solely. The applicable License Fee and club membership is
            specified on the GYMStar Calculators Ltd. website or in the specific
            price proposal provided by GYMStar Calculators Ltd. or an Authorized
            Reseller. The payment terms and conditions for the License Fee and
            Club membership are payable to GYMStar Calculators Ltd. and
            specified on the GYMStar Calculators Ltd. invoice or in the specific
            price proposal provided by GYMStar Calculators Ltd. . All fees paid
            to GYMStar Calculators Ltd. are non-refundable except as explicitly
            permitted from time to time on the GYMStar Calculators Ltd. Website.
            GYMStar Calculators Ltd. may terminate this Agreement and invalidate
            Customer’s Activation Key if the billing or contact information is
            false, fraudulent, or invalid.
          </p>
          <ol start="9">
            <li>
              <strong>
                <u>CONFIDENTIALITY</u>
              </strong>
            </li>
          </ol>
          <p>
            Customer and GYMStar Calculators Ltd. agree to maintain the
            confidentiality of any confidential or proprietary information of
            one party (the “disclosing party”) received by the other party (the
            “receiving party”) during the term of, or prior to entering into,
            this Agreement that the receiving party should know is considered
            confidential or proprietary by the disclosing party based on the
            circumstances surrounding the disclosure, including, without
            limitation, non-public technical and business information
            (“Confidential Information”). The Licensed Software is copyrighted
            and shall be deemed GYMStar Calculators Ltd. ’s Confidential
            Information. The Documentation is copyrighted material of GYMStar
            Calculators Ltd. . This section shall not apply to any information
            that is or becomes publicly available through no breach of this
            Agreement by the receiving party or is independently developed by
            the receiving party without access to or use of the Confidential
            Information of the disclosing party. The foregoing confidentiality
            obligations will not restrict either party from disclosing
            Confidential Information of the other party pursuant to the order or
            requirement of a court, administrative agency, or other governmental
            body, provided that the party required to make such a disclosure
            gives reasonable notice to the other party to enable the other party
            to seek a protective order or otherwise limit such disclosure. The
            receiving party of any Confidential Information of the disclosing
            party agrees not to use the disclosing party’s Confidential
            Information for any purpose except as necessary to fulfill its
            obligations and exercise its rights under this Agreement. The
            receiving party shall protect the secrecy of and avoid disclosure
            and unauthorized use of the disclosing party’s Confidential
            Information with no less than reasonable care. All the disclosing
            party’s information remains the property of the disclosing party.
          </p>
          <ol start="10">
            <li>
              <strong>
                <u>LIMITATION OF LIABILITY.</u>
              </strong>
            </li>
          </ol>
          <p>
            <u>10.1 Consequential Damages Waiver.</u>
          </p>
          <p>
            IN NO EVENT SHALL GYMSTAR CALCULATORS LTD. OR ITS LICENSORS HAVE ANY
            LIABILITY FOR ANY INCIDENTAL, CONSEQUENTIAL, INDIRECT, SPECIAL, OR
            PUNITIVE DAMAGES ARISING OUT OF OR IN CONNECTION WITH THIS
            AGREEMENT, REGARDLESS OF THE FORM OF THE ACTION, WHETHER IN
            CONTRACT, TORT (INCLUDING NEGLIGENCE), STRICT PRODUCT LIABILITY, OR
            OTHERWISE, EVEN IF ANY REPRESENTATIVE OF GYMSTAR CALCULATORS LTD.
            HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES AND
            NOTWITHSTANDING THE FAILURE OF THE ESSENTIAL PURPOSE OF THIS
            AGREEMENT OR ANY LIMITED REMEDY HEREUNDER.
          </p>
          <ol start="11">
            <li>
              <strong>
                <u>GENERAL.</u>
              </strong>
            </li>
          </ol>
          <p>
            Notices hereunder shall be in writing and addressed to Customer at
            the address provided when purchasing this license or, in the case of
            GYMStar Calculators Ltd. , when addressed to GYMStar Calculators
            Ltd. LP, Attn: Director of Operations, 5251 Lindell Rd. Suite 103
            Las Vegas NV 89118
          </p>

          <div class="clearboth"></div>
        </div>
      </div>
    </div>
  );
}
