import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import Header2 from "../../Components/Header/header2";
import { getFilteredWorkout } from "../../Store/actions/station";
import "./App.css";
import Table from "./Table";
import WorkoutRecord from "./workoutRecord";
function MuscleRecord(props) {
  const user = useSelector((store) => store?.auth?.user[0]);
  const [mustleType, setMustleType] = useState("");
  const [routineName, setRoutineName] = useState("");
  const reset = () => {
    setRoutineName("");
    setMustleType("");
  };
  const workoutRecord = useSelector((store) => store?.station?.workoutRecord);
  console.log(user, "user");

  useEffect(() => {
    if (user?._id) {
      props.getFilteredWorkout({ userId: user._id }, mustleType, routineName);
    }
  }, [user?._id, mustleType, routineName]);
  let Muscletype = [
    "Abdominal",
    "Triceps",
    "Biceps",
    "Back",
    "Back",
    "Chest Upper",
    "Chest Lower",
    "XTraM",
    "Forearms",
    "Lower Legs Calves",
    "Legs Front",
    "Legs Back",
    "Gluteus Maxim(Butt)",
    "Deltoids Back",
    "Deltoids Front",
    "Gluteus Maxim(Butt)",
    "CrossTrain",
  ];

  // useEffect(() => {
  //   if (user?._id) {
  //     props.getFilteredWorkout({ userId: user._id }, "", "");
  //   }
  // }, [user?._id]);
  // useEffect(()=>console.log(workoutRecord,"workoutRecord"),[workoutRecord])
  return (
    <>
      <Header2 text={"WORKOUT RECORD"} />
      <div>
        <div className="Muscle-Header">
          <div className="Head1">Professional Calculator Results</div>
          <button className="Button-Blue">WORKOUT LOG VIDEO TRAINING</button>
          <div className="Head1">Your Workout Records</div>
        </div>
        <div className="Muscle-Body">
          <div>
            <select
              className="Select"
              value={mustleType}
              placeholder="Muscle Type"
              onChange={(ev) => setMustleType(ev.target.value)}
            >
              <option value={""}>Muscle Type</option>
              {Muscletype?.map((e) => (
                <option value={e}>{e}</option>
              ))}
            </select>
            <select
              className="Select"
              value={routineName}
              placeholder="Routine"
              onChange={(ev) => setRoutineName(ev.target.value)}
            >
              <option value={""}>Routine</option>
              {user?.allWorkoutRoutineName?.map((e) => (
                <option value={e}>{e}</option>
              ))}
            </select>
            <button className="Button-Blue margin">RESET</button>
            <button className="Button-Blue margin">GET MAX RESULTS</button>
          </div>
          {/* <div>
            <select className="Select" value="Muscle-type">
              <option value="Muscle-type">Muscle-type</option>
              <option></option>
              <option></option>
            </select>
          </div> */}
        </div>
        {/* <div className="parent-Table">
          <div className="Inner-Table">
            <Table text={"MAX RESULT STATION TOTALS"} />
          </div>
          <div className="Inner-Table">
            <Table text={"TOP RESULTS & HIGHEST NUMBERS"} />
          </div>
        </div> */}
        {!workoutRecord?.length ? (
          <div>
            <h3>No Data Avalible</h3>
          </div>
        ) : (
          workoutRecord?.reverse().map((e, i) => {
            console.log(e, "workoutRecord.workoutRecord");
            return <WorkoutRecord workout={e} key={i} />;
          })
        )}
        <div className="Delete">
          <button className="Button-Blue margin">Select All</button>
          <button className="Button-Blue margin">Delete All Selected</button>
        </div>
      </div>
    </>
  );
}
const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  getFilteredWorkout: (id, mustleType, routineName) =>
    dispatch(getFilteredWorkout(id, mustleType, routineName)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MuscleRecord);
