import React from "react";
import LeftArrow from "../../Assets/Images/leftarrow.png";
import arbImage from "../../Assets/Images/arb.png";
import Title from "../../Assets/Images/title.png";
import Google from "../../Assets/Images/g.png";
import { Link } from "react-router-dom";
export default function Header() {
  return (
    <div className="routine-Header">
      <div className="Header-Child">
        <div className="img-responsive-header">
          <img src={Title} />
        </div>
        <div className="Time none">
          <Link to={"/Workout"}>
            <img
              src={LeftArrow}
              className="img-responsive"
              style={{
                marginRight: "26px",
                cursor: "pointer",
              }}
            />
          </Link>
          <img
            src={arbImage}
            className="img-responsive "
            style={{
              marginRight: "26px",
              cursor: "pointer",
            }}
          />
          <div
            style={{
              marginRight: "26px",
              cursor: "pointer",
            }}
          >
            Time:<span>13:30</span>
          </div>
          <img
            style={{
              marginRight: "26px",
              cursor: "pointer",
            }}
            src={Title}
          />
        </div>
        <div className="none">
          <img src={Google} className="img-responsive" />
        </div>
      </div>
      <div className="responsive-Header-2">
        <div>
          <Link to={"/Workout"}>
            <img
              src={LeftArrow}
              className="img-responsive"
              style={{
                marginRight: "26px",
                cursor: "pointer",
              }}
            />
          </Link>
          <img
            src={arbImage}
            className="img-responsive "
            style={{
              marginRight: "26px",
              cursor: "pointer",
            }}
          />
        </div>
        <div
          style={{
            marginRight: "26px",
            cursor: "pointer",
          }}
        >
          Time:<span>13:30</span>
        </div>
        <div>
          <img src={Google} className="img-responsive" />
        </div>
      </div>
      <div className="station-btn">
        <button>Add station</button>
      </div>
    </div>
  );
}
