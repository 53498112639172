import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { connect, useSelector } from "react-redux";
import Header2 from "../../Components/Header/header2";
import WorkoutCards from "../../Components/WorkoutCards";
import { getDay, getFilterWorkout } from "../../Store/actions/station";
import { Muscletype } from "../../Store/constant";
import "./Workout.css";
function Workout(props) {
  const [workoutData, setWorkoutData] = useState([]);
  const user = useSelector((store) => store?.auth?.user[0]);
  const filteredWorkoutData = useSelector(
    (store) => store?.station?.filteredWorkoutData
  );
  useEffect(() => {
    if (user?._id) {
      props.getFilterWorkout(user?._id, getDay());
    }
  }, [user?._id]);
  useEffect(() => {
    let arr = [];
    Muscletype?.map((e, i) => {
      let data = filteredWorkoutData?.filter((ev) => ev?.mustleType === e);
      arr.push({ text: e, workout: data });
    });
    setWorkoutData(arr);
  }, [filteredWorkoutData]);
  let data = [
    { text: "Abdominal", workout: ["Abdominal"] },
    { text: "arm", workout: ["Triceps", "Biceps", "Back"] },
    { text: "Back", workout: ["Back"] },
    { text: "Chest", workout: ["Chest Upper", "Chest Lower"] },
    { text: "Extra Muscle", workout: ["XTraM"] },
    { text: "Forearms", workout: ["Forearms"] },
    { text: "Legs (Lower) Calves", workout: ["Lower Legs Calves"] },
    {
      text: "Legs (Upper)",
      workout: ["Legs Front", "Legs Back", "Gluteus Maxim(Butt)"],
    },
    {
      text: "Shoulder",
      workout: ["Deltoids Back", "Deltoids Front", "Gluteus Maxim(Butt)"],
    },
    {
      text: "Train",
      workout: ["CrossTrain"],
    },
  ];
  return (
    <>
      <Header2 text={"Workout"} />
      <div className="container Workout">
        <div className="Cards-Parent row">
          {data?.map((e) => (
            <WorkoutCards data={e} />
          ))}
        </div>
      </div>
    </>
  );
}
const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  getFilterWorkout: (id, workoutDays) =>
    dispatch(getFilterWorkout(id, workoutDays)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Workout);
