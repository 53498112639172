import React from "react";
import Header2 from "../../Components/Header/header2";
import "./bodyprofileFemale.css";
import FemaleInfo from "./FemaleInfo";
import FemaleForm from "./FemaleForm";
export default function BodyProfileFemale() {
  return (
    <>
      <Header2 text={"FEMALE BODY PROFILE"} />
      <div className="BodyProfileMale">
        <div className="innerBodyProfileMale row">
          <div className="col-md-6 left col-sm-12">
            <FemaleInfo />
          </div>
          <div className="col-md-6 left col-sm-12">
            <FemaleForm />
          </div>
        </div>
      </div>
    </>
  );
}
