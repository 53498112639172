import React, { useState } from "react";
import { Modal } from "antd";
import { gymTools, Muscletype } from "../../Store/constant";
import "./createStationModal.css";
import CheckBox from "../../Components/checkbox";
import { connect, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { createStationFn } from "../../Store/actions/station";
import Loader from "../../Components/Loader/loader";
function CreateStationModal({
  isModalOpen,
  showModal,
  handleCancel,
  handleOk,
  ...props
}) {
  const [RoutineNumber, setRoutineNumber] = useState([]);
  const [orderNumber, setOrderNumber] = useState([]);
  const [userInfo, setUserInfo] = useState({});
  const [tools, setTools] = useState("push Ups");
  const [loader, setLoader] = useState(false);
  const [sheduleOrder, setSheduleOrder] = useState(1);
  const [muscletype, setMuscletype] = useState("Abdominal");
  const user = useSelector((store) => store?.auth?.user[0]);
  const cleanState = () => {
    setMuscletype("Abdominal");
    setSheduleOrder(1);
    setLoader(false);
    document.getElementById("station-Create").reset();
    setTools("push Ups");
    setUserInfo({});
    setOrderNumber([]);
    setRoutineNumber([]);
    handleCancel();
  };
  const handleChange = (ev) => {
    ev.preventDefault();
    if (user && localStorage.getItem("authToken")) {
      setLoader(true);
      if (
        muscletype?.length &&
        tools?.length &&
        RoutineNumber?.length &&
        orderNumber?.length &&
        userInfo?.workoutTitle &&
        userInfo?.workoutDiscloser &&
        userInfo?.workoutRoutineName &&
        userInfo?.stationNumber
      ) {
        //         {
        //           "mustleType": "chest lower",
        //           "userId": "639cdf6270bfc6d73c5d19bd",
        //           "stationNumber": 101,
        //           "tools": "bench",
        //           "workoutRoutineName": "Xammp",
        //           "workoutDiscloser": "straight bar bench",
        //           "workoutRoutineNumber": [1,3],
        //           "workoutTitle": "ClsbarBench",
        //           "workoutOrder": 1,
        //           "workoutDays": ["monday","wednsday"]
        // }
        let data = {
          ...userInfo,
          mustleType: muscletype,
          tools: tools,
          workoutOrder: sheduleOrder,
          workoutRoutineNumber: RoutineNumber,
          workoutDays: orderNumber,
          userId: user?._id,
          // sheduleOrder:
        };
        props.createStation(data, setLoader, cleanState);
      } else {
        toast.error("please fill all fields");
        setLoader(false);
      }
    } else {
      toast.error("You must be logged in");
    }
  };
  return (
    <Modal
      // title="Basic Modal"
      open={isModalOpen}
      centered
      // onOk={handleOk}
      onCancel={handleCancel}
      footer={false}
    >
      <form
        className="station-Create"
        id="station-Create"
        onSubmit={handleChange}
      >
        <div className="form-station">
          <div className="form-station-child">
            <label>Muscle type</label>
            <select
              Name="Select"
              value={muscletype}
              onChange={(ev) => setMuscletype(ev.target.value)}
            >
              {Muscletype?.map((e) => (
                <option value={e}>{e}</option>
              ))}
            </select>
          </div>
          <div className="form-station-child">
            <label>Station Number</label>
            <input
              type={"number"}
              value={userInfo?.stationNumber}
              onChange={(ev) =>
                setUserInfo({ ...userInfo, stationNumber: ev.target.value })
              }
            />
          </div>
        </div>
        <div className="form-station">
          <div className="form-station-child">
            <label>Tools</label>
            <select
              Name="Select"
              value={tools}
              onChange={(ev) => setTools(ev.target.value)}
            >
              {gymTools?.map((e) => (
                <option value={e}>{e}</option>
              ))}
            </select>
          </div>
          <div className="form-station-child">
            <label>Workout Routine Name</label>
            <input
              type={"text"}
              value={userInfo?.workoutRoutineName}
              onChange={(ev) =>
                setUserInfo({
                  ...userInfo,
                  workoutRoutineName: ev.target.value,
                })
              }
            />
          </div>
        </div>
        <div className="form-station">
          <div className="form-station-child">
            <label>Muscle workout Discolsure</label>
            <input
              type={"text"}
              value={userInfo?.workoutDiscloser}
              onChange={(ev) =>
                setUserInfo({ ...userInfo, workoutDiscloser: ev.target.value })
              }
            />
          </div>
          <div className="form-station-child">
            <label>Muscle Routine Number</label>
            <CheckBox
              data={[1, 2, 3, 4, 5, 6, 7]}
              setIndex={setRoutineNumber}
              Index={RoutineNumber}
            />
          </div>
        </div>
        <div className="form-station">
          <div className="form-station-child">
            <label>Title</label>
            <input
              type={"text"}
              value={userInfo?.workoutTitle}
              onChange={(ev) =>
                setUserInfo({ ...userInfo, workoutTitle: ev.target.value })
              }
            />
          </div>
          <div className="form-station-child">
            <label className="SheduleOrder">
              Shedule order and day
              <select
                Name="Select"
                value={sheduleOrder}
                onChange={(ev) => setSheduleOrder(ev.target.value)}
              >
                {[1, 2, 3, 4, 5, 6, 7,8,9,10]?.map((e) => (
                  <option value={e}>{e}</option>
                ))}
              </select>
            </label>
            <CheckBox
              data={[
                "monday",
                "tuesday",
                "wednesday",
                "thursday",
                "friday",
                "saturday",
                "sunday",
              ]}
              setIndex={setOrderNumber}
              Index={orderNumber}
              days={true}
            />
          </div>
        </div>
        <button
          type="submit"
          className="Button-Blue margin"
          onClick={handleChange}
        >
          {loader ? <Loader /> : "Save"}
        </button>
      </form>
    </Modal>
  );
}
const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  createStation: (data, setLoader, cleanState) =>
    dispatch(createStationFn(data, setLoader, cleanState)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateStationModal);
