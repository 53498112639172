import React from "react";
import { Link } from "react-router-dom";
export default function WorkoutCards({ data, ...props }) {
  return (
    <div
      className=" col-md-4
     col-sm-6 mb-3"
    >
      <div className="Cards-main">
        <h4>{data?.text}</h4>
        <ul>
          {data?.workout?.map((e, i) => (
            <li>
              <Link
                to={`/Workout/Routine?mustleType=${data?.text}&mustleGroup=${e}`}
              >
                {e}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
// routineName=${e?.workoutRoutineName}&stationNumber=${e?.stationNumber}
