import React from "react";
import Image from "../../Assets/Images/GymStarPro-Bronze-Membership-300x300.jpg";
export default function BronzeInfo() {
  return (
    <div className="BronzeInfo">
      <div className="MEMBERSHIP-Level">
        <h3>MEMBERSHIP LEVEL</h3>
        <a href="">CHANGE</a>
      </div>
      <p>
        You have selected the{" "}
        <strong className="color"> Bronze Membership</strong> level.
      </p>
      <div className="MEMBERSHIP-Level-Header">
        <h1>
          GYMSTAR CALCULATORS WILL CHANGE THE ATHLETIC WORLD STARTING WITH
          GYMSTAR BRONZE MEMBERSHIP.
        </h1>
      </div>
      <div style={{ display: "flex" }} className="membership-price">
        <img src={Image} style={{ marginRight: "20px" }} />
        <div>
          <p>
            The price for membership is{" "}
            <strong className="color">$24.96</strong> now.
          </p>

          <p>Membership expires after 1 Year.</p>
          <p>
            Do you have a discount code? Click here to enter your discount code.
          </p>
        </div>
      </div>
    </div>
  );
}
