import React, { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import "./table.css";
export default function StationCard({
  data,
  key,
  selectAll,
  onSelectionChange,
}) {
  const [isSelected, setIsSelected] = useState(false);

  useEffect(() => {
    setIsSelected(selectAll);
  }, [selectAll]);

  const handleCheckboxChange = () => {
    const updatedSelection = !isSelected;
    setIsSelected(updatedSelection);
    onSelectionChange(data._id, updatedSelection);
  };

  return (
    <div className="innerStation" key={key}>
      <div className="innerStation-header">
        <div className="header-title">
          <h4 className="header-title-checkBox">
            <input
              type={"checkbox"}
              checked={isSelected}
              onChange={handleCheckboxChange}
            />
            {data?.mustleType}
          </h4>
          <h4>Station No:{data?.stationNumber}</h4>
        </div>
        {/* <button className="Button-Blue btn-save"> */}
        <CSVLink
          className="Button-Blue btn-save"
          data={[
            {
              Tools: data.tools,
              mustleType: data?.mustleType,
              stationNumber: data?.stationNumber,
              WorkoutRoutineName: data?.workoutRoutineName,
              MuscleworkoutDisclosure: data?.workoutDiscloser,
              WorkoutRoutineNumber: data?.workoutRoutineNumber.join(),
              Title: data?.workoutTitle,
              sheduleOrder: data?.workoutOrder,
              sheduleDay: data?.workoutDays.join(),
            },
          ]}
        >
          Save
        </CSVLink>
        {/* </button> */}
      </div>
      <div className="innerStation-Body">
        <div className="Body-parent">
          <div className="Body-parent-text bold">Tools</div>
          <div className="Body-parent-text">{data?.tools}</div>
          <div className="Body-parent-text bold">Workout Routine Name</div>
          <div className="Body-parent-text-2">{data?.workoutRoutineName}</div>
        </div>
        <div className="Body-parent">
          <div className="Body-parent-text bold">Muscle workout Disclosure</div>
          <div className="Body-parent-text">{data?.workoutDiscloser}</div>
          <div className="Body-parent-text bold">Workout Routine Number</div>
          <div className="Body-parent-text-2">
            {[1, 2, 3, 4, 5, 6, 7].map((e, i) => (
              <div
                className={`days-checkBox ${
                  data?.workoutRoutineNumber.includes(e) ? "Active" : ""
                }`}
                key={i}
              >
                {e}
              </div>
            ))}
          </div>
        </div>
        <div className="Body-parent">
          <div className="Body-parent-text bold">Title</div>
          <div className="Body-parent-text">{data?.workoutTitle}</div>
          <div className="Body-parent-text bold">
            <div>shedule Order and Day</div>
            <div>{data?.workoutOrder}</div>
          </div>
          <div className="Body-parent-text-2">
            {[
              "monday",
              "tuesday",
              "wednesday",
              "thursday",
              "friday",
              "saturday",
              "sunday",
            ].map((e, i) => (
              <div
                className={`days-checkBox ${
                  data?.workoutDays.includes(e) ? "Active" : ""
                }`}
                key={i}
              >
                {e.slice(0, 1)}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
