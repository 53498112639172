import React from "react";
import Header2 from "../../Components/Header/header2";
import MaleInfo from "./MaleInfo";
import "./bodyprofilemale.css";
import MaleForm from "./MaleForm";
export default function BodyProfileMale() {
  return (
    <>
      <Header2 text={"MALE BODY PROFILE"} />
      <div className="BodyProfileMale">
        <div className="innerBodyProfileMale row">
          <div className="col-md-6 left col-sm-12">
            <MaleInfo />
          </div>
          <div className="col-md-6 left col-sm-12">
            <MaleForm />
          </div>
        </div>
      </div>
    </>
  );
}
