import moment from "moment";
import React, { useEffect, useState } from "react";
export default function WorkoutRecord({ workout, key }) {
  // console.log(workout);
  const [sets, setSets] = useState([]);
  const [reps, setReps] = useState([]);
  const [weight, setWeight] = useState([]);
  const [total, setTotal] = useState([]);
  const [data, setData] = useState({});
  useEffect(() => {
    let arr = [];
    let arr2 = [];
    let arr3 = [];
    let arr4 = [];
    setData(workout?.workout[0]);
    workout?.workout?.map((a, j) => {
      console.log(a);
      arr.push(a.set);
      arr2.push(a.weight);
      arr3.push(a.reps);
      console.log(j);
      if (j === 0) {
        arr4.push(a.weight * a.reps);
      } else {
        arr4.push(arr4[j - 1] + a.weight * a.reps);
      }
    });
    setSets(arr);
    setWeight(arr2);
    setReps(arr3);
    setTotal(arr4);
  }, [workout]);
  let time = workout?.workout[workout?.workout?.length - 1]?.date;
  return (
    <div className="innerStation" key={key}>
      <div className="innerStation-header">
        <div className="header-title">
          <h4 className="header-title-checkBox">
            <input type={"checkbox"} />
            {data?.mustleGroup}
          </h4>
          <h4>WEIGHT LIFTING RESULTS</h4>
          <h4></h4>
          {/* <h4>4:18 pm</h4> */}
        </div>
        <div className="header-title">
          <h4>{time}</h4>
        </div>
      </div>
      <div className="innerStation-Body">
        <div className="innerStation-Child Body-parent">
          <div
            className="innerStation-Text-Child"
            style={{ textDecoration: "underline" }}
          >
            Mustle Type
          </div>
          <div
            className="innerStation-Text-Child"
            style={{ textDecoration: "underline" }}
          >
            Station No.
          </div>
          <div
            className="innerStation-Text-Child"
            style={{ textDecoration: "underline" }}
          >
            Set no.
          </div>
          {sets?.map((set) => (
            <div
              className="innerStation-Text-Child"
              style={{ justifyContent: "center" }}
            >
              {set}
            </div>
          ))}
        </div>
        <div className="innerStation-Child Body-parent">
          <div className="innerStation-Text-Child">{data?.mustleType}</div>
          <div className="innerStation-Text-Child">{data?.stationNumber}</div>
          <div
            className="innerStation-Text-Child"
            style={{ textDecoration: "underline" }}
          >
            Reps
          </div>
          {reps?.map((rep) => (
            <div
              className="innerStation-Text-Child"
              style={{ justifyContent: "center" }}
            >
              {rep}
            </div>
          ))}
        </div>
        <div className="innerStation-Child Body-parent">
          <div
            className="innerStation-Text-Child"
            style={{ textDecoration: "underline" }}
          >
            Disclosure Support
          </div>
          <div
            className="innerStation-Text-Child"
            style={{ textDecoration: "underline" }}
          >
            {/* {data} */}
            Machine
          </div>
          <div
            className="innerStation-Text-Child"
            style={{ textDecoration: "underline" }}
          >
            Weight
          </div>
          {weight?.map((wei) => (
            <div
              className="innerStation-Text-Child"
              style={{ justifyContent: "center" }}
            >
              {wei}
            </div>
          ))}
        </div>
        <div className="innerStation-Child Body-parent">
          <div className="innerStation-Text-Child">
            {workout?.StationData[0]?.workoutDiscloser}
          </div>
          <div className="innerStation-Text-Child">
            {/* {data} */}
            {workout?.StationData[0]?.tools}
          </div>
          <div className="innerStation-Text-Child">Total</div>
          {total?.map((tot) => (
            <div
              className="innerStation-Text-Child"
              style={{ justifyContent: "center" }}
            >
              {tot}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
