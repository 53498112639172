import React from "react";
import Image from "../../Assets/Images/GymStarPro-Silver-Membership-300x300.jpg";
export default function SilverInfo() {
  return (
    <div className="BronzeInfo">
      <div className="MEMBERSHIP-Level">
        <h3>MEMBERSHIP LEVEL</h3>
        <a href="">CHANGE</a>
      </div>
      <p>
        You have selected the{" "}
        <strong className="color"> Silver Membership</strong> level.
      </p>
      <div className="MEMBERSHIP-Level-Header">
        <h1>SILVER MEMBERSHIP APP</h1>
        <h1>
          GYMSTAR CALCULATOR APPS WILL CHANGE THE ATHLETIC WORLD FOR THE
          ATHLETE.
        </h1>
      </div>
      <div className="membership-price">
        <img src={Image} style={{ marginRight: "20px" }} />
        <div>
          <p>
            The price for membership is{" "}
            <strong className="color">$44.95</strong> now.
          </p>

          <p>Membership expires after 1 Year. </p>
          <p>
            Do you have a discount code? Click here to enter your discount code.
          </p>
        </div>
      </div>
    </div>
  );
}
