import React from "react";
import Slider from "./Slider";
import RegisterationImg from "../../Assets/home/gymstar-free-registration.jpg";
import LoginImg from "../../Assets/home/gymstar-login.jpg";
import AppImg from "../../Assets/home/gymstar-app-download.jpg";
import "./home.css"
export default function Home() {
  return (
    <div>
      <Slider />
      <div className="introLfinks">
        <div class="introLinks-Child">
          <a className="introLink-Img"
          // href="http://gymtime.me/membership-account/membership-checkout/?level=6 _blank"
          >
            <img
              src={RegisterationImg}
              alt=""
              loading="lazy"
            />
          </a>
        </div>

        <div class="introLinks-Child">
          <a className="introLink-Img"
          // href="http://gymtime.me/login/"
          >
            <img
              src={LoginImg}
              alt=""
              loading="lazy"
              itemprop="image"
            />
          </a>
        </div>

        <div class="introLinks-Child">
          <a className="introLink-Img"
          // href="http://gymtime.me/gymstar-calculator-downloads/"
          >
            <img
              src={AppImg}
              alt=""
              loading="lazy"
              itemprop="image"
            />
          </a>
        </div>
      </div>
    </div>
  );
}
