import React from "react";
import MeasurmentsImg from "../../Assets/Images/Ladies-Body-Measurement-Graph-1.jpg";
export default function FemaleInfo() {
  return (
    <div>
      <div id="text-block-4" class="mk-text-block   ">
        <p>
          <span>
            GYMstar is about calculations and totals for knowledge of success.
            To further your tracking of results we offer you a profile page.
            &nbsp;
          </span>
        </p>
        <p>
          <span>
            An interesting fact about body measurements is that research has
            shown that it is not so much a woman’s weight that determines her
            attractiveness, as it is her waist-to-hip ratio (WHR). It seems that
            women with a WHR of 0.7.{" "}
          </span>
        </p>
        <p>
          <span>
            However, while we may not fit a body type exactly, we will fit into
            one category better than the next and have a predominant body
            type.&nbsp;It is important to determine which of these classical
            body types’ best defines you so that you have the best chances of
            reshaping your body.
          </span>
        </p>
        <p>
          <span>
            If you do not have time to calculate body fat, the&nbsp;least you
            should do is buy a tape measure and keep records on your body
            measurements. This is good practice even if you are calculating body
            fat percentages. Taking your measurements is a fantastic method of
            keeping track of your changing body shape as you get fit. When you
            burn fat and increase muscle mass, there may be times when you weigh
            a little more despite the fact that your body is getting smaller and
            tighter.
          </span>
        </p>
        <p>
          <span>
            For a complete picture of your progress when taking body
            measurements, measure yourself in 16 different places. Otherwise,
            for a quick approach simply measure chest, waist and hips, including
            either lower or upper body&nbsp;
          </span>
        </p>
        <p>
          <span>
            measurements – depending on your problem area (e.g. thighs, arms).
          </span>
        </p>
        <p>
          <span>Before you start measuring, remember to:</span>
        </p>
        <ol>
          <li>
            <span>Use a non-stretchable tape</span>
          </li>
          <li>
            <span>
              Make sure the tape measure is level around your body and parallel
              to the floor
            </span>
          </li>
          <li>
            <span>Keep tape close to your skin without depressing it.</span>
          </li>
        </ol>
        <p>
          <b>MEASUREMENTS:</b>
        </p>
        <p>
          <b>Neck:&nbsp;</b>
          <span>Measure around center.</span>
        </p>
        <p>
          <b>Shoulders:&nbsp;</b>
          <span>Measure directly around center level of shoulders. </span>
        </p>
        <p>
          <b>Upper Arm (Triceps):&nbsp;</b>
          <span>
            Measure directly above your Bicep and below shoulder, as high up as
            possible.{" "}
          </span>
        </p>
        <p>
          <b>Middle Arm (Bicep):&nbsp;</b>
          <span>Measure middle of Bicep and below triceps. </span>
        </p>
        <p>
          <b>Forearms:&nbsp;</b>
          <span>Measure below your elbows – around fullest part. </span>
        </p>
        <p>
          <b>Chest: </b>
          <span>
            Measure all the way around just in-line with arm pit area.{" "}
          </span>
        </p>
        <p>
          <b>Bust:</b>
          <span>
            &nbsp;Measure all the way around your on-line of your nipples.
            &nbsp;
          </span>
        </p>
        <p>
          <b>Midriff:&nbsp;</b>
          <span>
            Measure directly under your breasts, as high up as possible.{" "}
          </span>
        </p>
        <p>
          <b>Waist:&nbsp;</b>
          <span>
            Measure at its narrowest point width-wise, usually below dorsi back
            muscle and above navel.{" "}
          </span>
        </p>
        <p>
          <b>Abdomen:&nbsp;</b>
          <span>
            Measure at its narrowest point width-wise, usually center line with
            &nbsp;navel.{" "}
          </span>
        </p>
        <p>
          <b>Hips:&nbsp;</b>
          <span>
            Measure at its narrowest point width-wise, usually just below navel.
            &nbsp;_
          </span>
        </p>
        <p>
          <b>Buttocks:&nbsp;</b>
          <span>Measure around the widest part of the hipbones. &nbsp;</span>
        </p>
        <p>
          <b>Leg Upper:&nbsp;</b>
          <span>
            Measure around the highest accessible point of leg level with
            crotch.
          </span>
        </p>
        <p>
          <b>Leg Middle:&nbsp;</b>
          <span>
            Measure around fullest part of upper leg while standing. &nbsp;
          </span>
        </p>
        <p>
          <b>Leg Lower:</b>
          <span>
            {" "}
            Measure around the fullest part above the knee while standing.
          </span>
        </p>
        <p>
          <b>Calves:&nbsp;</b>
          <span>Measure around fullest part. &nbsp;</span>
        </p>
        <p>
          <b>Ankle:&nbsp;</b>
          <span>Measure around fullest part</span>
        </p>

        <div class="clearboth"></div>
      </div>
      <div className="MeasurmentsImage">
        <img src={MeasurmentsImg} />
      </div>
    </div>
  );
}
