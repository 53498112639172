import React from "react";
import Image from "../../Assets/Images/GoldBanner.jpg";
export default function GoldInfo() {
  return (
    <div className="BronzeInfo">
      <div className="MEMBERSHIP-Level">
        <h3>MEMBERSHIP LEVEL</h3>
        <a href="">CHANGE</a>
      </div>
      <p>
        You have selected the{" "}
        <strong className="color"> Gold Membership</strong> level.
      </p>
      <div className="MEMBERSHIP-Level-Header">
        <h1>GOLD MEMBERSHIP</h1>
        <h1>
          GYMSTAR CALCULATOR APPS WILL CHANGE THE ATHLETIC WORLD FOR THE
          ATHLETE.
        </h1>
      </div>
      <img src={Image} style={{ marginBottom: "20px" }} />
      <div class="pmpro_checkout-fields">
        <div class="pmpro_level_description_text">
          <p>
            <strong className="color">GOLD APP DESCRIPTION</strong>
          </p>
          <p>
            Gold app tool is for a serious athlete, professional athlete and
            trainers. But simple enough for a beginner.
          </p>
          <p>
            GYMStar Gold Membership App with the Smart Watch is an evolution in
            exercise tracking and inspiration.
          </p>
          <p>Gold Membership Excellent Features?</p>
          <ol>
            <li>Workout Routine with GYMStar Total and Station Totals</li>
            <li>GYMStar Routines or Your Routines</li>
            <li>Design As Many Routines as Needed</li>
            <li>Routine Numbering From Start to Finish</li>
            <li>Import Routines and Export Routines</li>
            <li>Delete Routines, Build New Routines</li>
          </ol>
          <p>Standard Features:</p>
          <p>
            Totals – are standard workout information for the station and muscle
            exercised.
          </p>
          <p>
            GYMStar Total – is your grand total for all totals combined during a
            daily workout. &nbsp;This is an inspirational total that drives you
            to do more.
          </p>
          <p>
            GYMStar Gold Calculator App is programmed to keep you informed while
            you are working out.
          </p>
          <p>Explanation for TOP Result &amp; Max Result Station Totals.</p>
          <ol>
            <li>
              <strong className="color">Max Weight</strong> (Station) is the
              highest weight total pushed or pulled in the searched Muscle Group
              and station number. Even if only one rep!
            </li>
            <li>
              <strong className="color">Max Weight HTotal</strong> (station) the
              highest workout day total. Not last results but the highest day.
            </li>
            <li>
              <strong className="color">Max Effort L7Days </strong>(Station) is
              station total.&nbsp; In last seven day period.
            </li>
            <li>
              <strong className="color">Max Effort Muscle Group</strong> (24H)
              is all stations totals in the muscle group added together in last
              24 hours.
            </li>
            <li>
              <strong className="color">Max Effort Muscle Group (L7Day)</strong>{" "}
              Total High is all stations in the muscle group added together for
              last 7 days workout total
              <strong className="color">
                . (Please put an (L) in front of 7 on Workout Records.)&nbsp;
                Max Effort L7Day{" "}
              </strong>
            </li>
          </ol>
          <p>Second Column</p>
          <ol start="6">
            <li>
              <strong className="color">Max Effort (24) </strong>is the highest
              single rep weight pushed or pulled. Disclosing the Muscle Group
              and Station number.
            </li>
            <li>
              <strong className="color">Max Effort 24H</strong> (Station) is the
              highest workout total weight pushed or pulled in a station.
              Disclosing the Muscle Group and Station number.
            </li>
            <li>
              <strong className="color">Max Effort L7Days</strong> (Station) is
              the highest workout total weight pushed or pulled in a station.
              Disclosing the Muscle Group and Station number.
            </li>
            <li>
              <strong className="color">Max Effort Muscle Group (24h)</strong>{" "}
              is the highest workout total weight pushed or pulled in a muscle
              group. Disclosing the Muscle Group and Station numbers.
            </li>
            <li>
              <strong className="color">
                Max Effort Muscle Group (L7Days)
              </strong>{" "}
              is the highest workout total weight pushed or pulled in a muscle
              group. Disclosing the Muscle Group and Station numbers.
            </li>
          </ol>
          <p>
            Top Results in the app is found in the Top Result button and very
            easy to find.
          </p>
          <p>
            Maximum Results are available when you execute a station calculate
            after looking at results you can swipe to the left and get your top
            results for that muscle group and station. For more information see
            training videos.
          </p>
          <p>Timer Feature</p>
          <ol>
            <li>
              <strong className="color">CrossTrain</strong> (Muscle Group) is an
              addition to the calculator system.&nbsp; You will find the feature
              after you click on calculate results.&nbsp; This will be further
              improved in next version. See Video for more instructions.
            </li>
            <li>
              <strong className="color">ExtraMuscle</strong>&nbsp;(Muscle Group)
              is for the purpose of other regions to define in your workout that
              are not listed as muscle group specific.
            </li>
          </ol>
          <p>
            Station Review List - The review list is for remembering what
            station number is within the muscle group.&nbsp; In the app is a
            button to see all the station numbers you added to the muscle
            group.&nbsp; You will understand as you use the app.&nbsp; See
            Station Log video and Gold Membership for training.
          </p>
          <p>
            Calculator – GYMStar family of calculator app memberships all start
            with the foundation of Last Results totals and GYMStar Total. The
            calculator function is to provide real time totals “Last Results and
            Today’s Results” that inspire you to work out a little more each
            time you step up to a station.
          </p>
          <p>
            WorkOut Records – is where you review your workout. &nbsp;This is
            best done from an Android phone, iPhone, tablet, or home computer.
            &nbsp;When your are ready just log into the system to review your
            information. &nbsp;Options are available to download as a CSV and
            opening in Excel format. For more information go to our{" "}
            <a href>Station Log and WorkOut Log Instructions</a>.
          </p>
          <p>
            Station Log - is where you further identify a station with a muscle
            group and workout routine. You have the option to download a station
            list with workout routines or build your own. The options will
            become self intuitive as you use the system. Don't forget to assign
            the routine number for the sequence you want the routine to be
            performed.
          </p>
          <p>
            Data – a workout station is always saved as long as you have a
            membership. If you need to delete a station this is performed in the
            Station Log.
          </p>
          <p>Russ Martin/ President/CEO</p>
          <p>
            {/* <img
              src="http://gymtime.me/wp-content/uploads/2016/11/Menu-GymStar-Logo-300x107.png"
              alt="menu-gymstar-logo"
            /> */}
          </p>
        </div>

        <div id="pmpro_level_cost">
          <div class="pmpro_level_cost_text">
            <p>
              The price for membership is{" "}
              <strong className="color">$44.95</strong> now.{" "}
            </p>
          </div>

          <div class="pmpro_level_expiration_text">
            <p>Membership expires after 1 Year.</p>
          </div>
        </div>

        <p id="other_discount_code_p" class="pmpro_small">
          Do you have a discount code?{" "}
          <a id="other_discount_code_a">
            Click here to enter your discount code
          </a>
          .
        </p>
      </div>
    </div>
  );
}
