import React from "react";
import { Link } from "react-router-dom";
export default function MembershipTable(params) {
  return (
    <table id="pmpro_levels_table" class="pmpro_table pmpro_checkout">
      <thead>
        <tr>
          <th>Level</th>
          <th>Price</th>
          <th>&nbsp;</th>
        </tr>
      </thead>
      <tbody>
        <tr class="odd">
          <td>Bronze Membership</td>
          <td>
            <strong>$24.96</strong> now. <br />
            Membership expires after 1 Year.{" "}
          </td>
          <td>
            {/* <button className="btn-Select"> */}
            <Link
              to="/MembershipLevels/checkout?type=Bronze"
              class="pmpro_btn pmpro_btn-select btn-Select"
            >
              Select
            </Link>
            {/* </button> */}
          </td>
        </tr>
        <tr class="">
          <td>Silver Membership</td>
          <td>
            <strong>$44.95</strong> now. <br />
            Membership expires after 1 Year.{" "}
          </td>
          <td>
            {/* <button className="btn-Select"> */}
            <Link
              to="/MembershipLevels/checkout?type=Silver"
              class="pmpro_btn pmpro_btn-select btn-Select"
            >
              Select
            </Link>
            {/* </button> */}
          </td>
        </tr>
        <tr class="odd">
          <td>Gold Membership</td>
          <td>
            <strong>$44.95</strong> now. <br />
            Membership expires after 1 Year.{" "}
          </td>
          <td>
            {/* <button className="btn-Select"> */}
            <Link
              to="/MembershipLevels/checkout?type=Gold"
              class="pmpro_btn pmpro_btn-select btn-Select"
            >
              Select
            </Link>
            {/* </button> */}
          </td>
        </tr>
        <tr class="">
          <td>Free Membership</td>
          <td>
            <strong>Free</strong>. <br />
            Membership expires after 3 Weeks.{" "}
          </td>
          <td>
            {/* <button className="btn-Select"> */}
            <Link
              class="pmpro_btn pmpro_btn-select btn-Select"
              to="/MembershipLevels/checkout?type=Free"
            >
              Select
            </Link>
            {/* </button> */}
          </td>
        </tr>
      </tbody>
    </table>
  );
}
