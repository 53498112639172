import React from "react";
import { ToastContainer } from "react-toastify";
import "./Styles/Style.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "antd/dist/antd.css";
import "react-toastify/dist/ReactToastify.css";
import { Provider } from "react-redux";
import store from "./Store/index";
import Router from "./AppRouter/index";
function App() {
  console.log("AAAAAA");
  return (
    <div className="App">
      <ToastContainer />
      <Provider store={store}>
        <Router />
      </Provider>
    </div>
  );
}

export default App;
