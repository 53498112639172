import React from "react";
import { useState } from "react";
import { useEffect } from "react";
export default function CheckBox({ data, Index, setIndex, days }) {
  return (
    <div className="Body-parent-text-2 parentStation">
      {data?.map((e, i) => (
        <div
          className={`days-checkBox ${Index?.includes(e) ? "Active" : ""}`}
          onClick={() => {
            if (Index?.includes(e)) {
              let arr = Index;
              let index = Index.indexOf(e);
              arr.splice(index, 1);
              setIndex([...arr]);
            } else {
              setIndex([e, ...Index]);
            }
          }}
          key={i}
        >
          {days ? e.slice(0, 1) : e}
        </div>
      ))}
    </div>
  );
}
