import React, { useState } from "react";
import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Header2 from "../../Components/Header/header2";
import Input from "../../Components/input";
import Loader from "../../Components/Loader/loader";
import { signinfn } from "../../Store/actions/auth";
import "./login.css";

function Login(props) {
  const [Object, setObject] = useState({});
  const [Loading, setLoading] = useState(false);
  let emailreg = "/^([a-z0-9_.-]+)@([da-z.-]+).([a-z.]{2,63})$/";
  const navigation = useNavigate();
  const onChange = (e) => {
    e.preventDefault();
    if (Object?.userName?.length && Object?.password?.length) {
      setLoading(true);
      props.signinfn(Object, navigation, setLoading, setObject);
    } else {
      console.log("errorr");
      // toast.error(
      //   !Object?.email.match(emailreg)
      //     ? "invalid email"
      //     : !Object?.password?.length
      //     ? "please fill password field"
      //     : "invalid"
      // );
    }
  };
  return (
    <>
      <Header2 text={"Login"} />
      <div className="Login alignCenter container">
        <form className="Inner-Login" onSubmit={onChange} id="Login-Submit">
          {/* <label>Email</label>
          <Input
            className="Input_type"
            required
            type="email"
            placeholder="Email"
            value={Object?.email}
            onChange={(e) => setObject({ ...Object, email: e.target.value })}
          /> */}
          <label>User Name</label>
          <Input
            className="Input_type"
            required
            type="text"
            placeholder="Username"
            value={Object?.userName}
            onChange={(e) => setObject({ ...Object, userName: e.target.value })}
          />

          <label>Password</label>
          <Input
            className="Input_type"
            required
            type="password"
            placeholder="Password"
            value={Object?.password}
            onChange={(e) => setObject({ ...Object, password: e.target.value })}
          />
          <div className="CheckBox">
            <Input
              type="checkbox"
              placeholder="Remember Me"
              value="Remember Me"
              style={{ marginRight: "10px" }}
              // value={Object?.password}
              // onChange={(e) => setObject({ ...Object, password: e.target.value })}
            />
            <span>Remember Me</span>
          </div>
          {/* <div></div> */}
          <div className="Button-Parent">
            <button disabled={Loading} className="Button" type="Submit">
              {Loading ? <Loader /> : "Login"}
            </button>
          </div>
          <p className="mt-2 mb-2">
            Don't have an account <Link to={"/MembershipLevels"}>Register</Link>
          </p>
        </form>
      </div>
    </>
  );
}
const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  signinfn: (data, navigation, setloader, cleanState) =>
    dispatch(signinfn(data, navigation, setloader, cleanState)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
