import React from "react";
import { getPreviousDay } from "../../Store/actions/station";
export default function Table({ fromData, toData }) {
  let prevdate = getPreviousDay();
  return (
    <table>
      <thead>
        <tr>
          <th>
            <div className="THead">Last Results</div>
          </th>
          <th>
            <div className="THead">
              {prevdate.getFullYear() +
                "/" +
                prevdate.getMonth() +
                "/" +
                prevdate.getDate()}
            </div>
          </th>
          <th>
            <div className="THead">today</div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className="Td">
            {" "}
            <div className="Weight">SET</div>
          </td>
          <td>
            <div className="Tbody">{fromData?.set}</div>
          </td>
          <td>
            <div className="Tbody">{toData?.set}</div>
          </td>
        </tr>
        <tr>
          <td className="Td">
            {" "}
            <div className="Weight">REPS</div>
          </td>
          <td>
            <div className="Tbody">{`${fromData?.reps || 0}/${
              fromData?.reps || 0
            }`}</div>
          </td>
          <td>
            <div className="Tbody">{`${toData?.newReps || 0}/${
              toData?.reps || 0
            }`}</div>
          </td>
        </tr>
        <tr>
          <td className="Td">
            {" "}
            <div className="Weight">Weight</div>
          </td>
          <td>
            <div className="Tbody">{fromData?.total}</div>
          </td>
          <td>
            <div className="Tbody">{toData.newWeight}</div>
          </td>
        </tr>
        <tr>
          <td className="Td">
            {" "}
            <div className="Weight">Total</div>
          </td>
          <td>
            <div className="Tbody"> {fromData?.total || 0}</div>
          </td>
          <td>
            <div className="Tbody">{toData?.total || 0}</div>
          </td>
        </tr>
        <tr>
          <td className="Td">
            {" "}
            <div className="Weight">GymStar Total</div>
          </td>
          <td>
            <div className="Tbody"> {fromData?.gymStarTotal || 0}</div>
          </td>
          <td>
            <div className="Tbody">{toData?.gymStarTotal || 0}</div>
          </td>
        </tr>
      </tbody>
    </table>
  );
}
