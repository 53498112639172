import React from "react";
import { useEffect } from "react";
import { connect } from "react-redux";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Foooter from "../Components/Footer/footer";
import Header from "../Components/Header/header";
import BodyProfileFemale from "../Pages/bodyprofileFemale/bodyprofileFemale";
import BodyProfileMale from "../Pages/bodyprofilemale/bodyprofilemale";
import Home from "../Pages/Home/home";
import Login from "../Pages/Login/login";
import MembershipCheckout from "../Pages/MembershipCheckout/MemberShipCheckout";
import MuscleRecord from "../Pages/workout-Record/Muscle_Record";
import Routine from "../Pages/Routines/Routines";
import MembershipLevels from "../Pages/Signup/MembershipLevels";
import SignUp from "../Pages/Signup/MembershipLevels";
import Workout from "../Pages/Workout/Workout";
import WorkoutStation from "../Pages/WorkoutStation/workoutStation";
import { userDatafn } from "../Store/actions/auth";
function AppRouter(props) {
  useEffect(() => {
    if (localStorage.getItem("authToken") && localStorage.getItem("auth_id")) {
      props.userDatafn();
    }
    console.log("ERERER");
  }, []);
  return (
    <BrowserRouter>
      <Header />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/Login" element={<Login />} />
        <Route exact path="/MembershipLevels" element={<MembershipLevels />} />
        <Route
          exact
          path="/MembershipLevels/checkout"
          element={<MembershipCheckout />}
        />
        <Route exact path="/SignUp" element={<SignUp />} />
        <Route exact path="/MuscleRecord" element={<MuscleRecord />} />
        <Route exact path="/workoutStation" element={<WorkoutStation />} />
        <Route exact path="/bodyprofilemale" element={<BodyProfileMale />} />
        <Route
          exact
          path="/BodyProfileFemale"
          element={<BodyProfileFemale />}
        />
        <Route exact path="/Workout/Routine" element={<Routine />} />
        <Route exact path="/Workout" element={<Workout />} />
        {/* <Route path="expenses" element={()=>{}} />
        <Route path="invoices" element={()=>{}} /> */}
      </Routes>
      <Foooter />
    </BrowserRouter>
  );
}
const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  userDatafn: () => dispatch(userDatafn()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AppRouter);
