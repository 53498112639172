import axios from "axios";
import { toast } from "react-toastify";
import { BACKEND_URL } from "../constant";
import { userDatafn } from "./auth";
import moment from "moment";

export const STATION_DATA = "STATION_DATA";

export const getStation = (payload) => {
  return {
    type: STATION_DATA,
    payload: payload,
  };
};

export const STATION_FROM_DATA = "STATION_FROM_DATA";

export const getStationfromdata = (payload) => {
  return {
    type: STATION_FROM_DATA,
    payload: payload,
  };
};

export const STATION_WORKOUT_RECORD = "STATION_WORKOUT_RECORD";

export const workoutRecordfn = (payload) => {
  return {
    type: STATION_WORKOUT_RECORD,
    payload: payload,
  };
};

export const GET_FILTERED_WORKOUT = "GET_FILTERED_WORKOUT";

export const getfilteredWorkout = (payload) => {
  return {
    type: GET_FILTERED_WORKOUT,
    payload: payload,
  };
};

export const createStationFn = (data, setloader, cleanState) => {
  return (dispatch) => {
    console.log(data);
    var config = {
      method: "post",
      url: `${BACKEND_URL}/createStation`,
      data: data,
    };
    axios(config)
      .then(function (response) {
        // console.log(response);
        // getStationFn(data?.userId);
        dispatch(userDatafn());
        cleanState();
        setloader(false);
        toast.success("Station Added successfully");
      })
      .catch(function (error) {
        console.log(error, "err");
        setloader(false);
        toast.error(error.response?.data?.message || "invalid response");
      });
  };
};

export const deleteStation = (id, data) => {
  return (dispatch) => {
    var config = {
      method: "delete",
      url: `${BACKEND_URL}/createStation/deleteStation`,
      data: {
        userId: id,
        stationId: data,
      },
    };
    axios(config)
      .then(function (response) {
        console.log(response);
        // getStationFn(data?.userId);
        dispatch(userDatafn());
        dispatch(getStationFn(id));
        // cleanState();
        // setloader(false);
        toast.success("Station deleted successfully");
      })
      .catch(function (error) {
        console.log(error, "err");
        // setloader(false);
        toast.error(error.response?.data?.message || "invalid response");
      });
  };
};

export const getStationFn = (id, stationNo, mustleType, routineName) => {
  return (dispatch) => {
    var config = {
      method: "post",
      url: `${BACKEND_URL}/createStation/filter?${
        routineName !== "" ? `workoutRoutineName=${routineName}` : ""
      }${
        mustleType !== ""
          ? `${routineName !== "" ? "&" : ""}mustleType=${mustleType}`
          : ""
      }${
        stationNo !== ""
          ? `${
              mustleType !== "" || routineName !== "" ? "&" : ""
            }stationNumber=${stationNo}`
          : ""
      }`,
      data: {
        userId: id,
      },
    };
    axios(config)
      .then(function (response) {
        // console.log(response?.data?.station);
        dispatch(getStation(response?.data?.station));
        // cleanState();
        // setloader(false);
      })
      .catch(function (error) {
        console.log(error, "err");
        dispatch(getStation([]));
        // setloader(false);
        // toast.error(error.response?.data?.message);
      });
  };
};

export const getFilterWorkout = (id, workoutDays) => {
  return (dispatch) => {
    var config = {
      method: "post",
      url: `${BACKEND_URL}/createStation/filter?${
        workoutDays !== "" ? `workoutDays=${workoutDays}` : ""
      }`,
      data: {
        userId: id,
      },
    };
    axios(config)
      .then(function (response) {
        dispatch(getfilteredWorkout(response?.data?.data));
        // cleanState();
        // setloader(false);
      })
      .catch(function (error) {
        console.log(error, "err");
        dispatch(getStation([]));
        // setloader(false);
        // toast.error(error.response?.data?.message);
      });
  };
};

export function getPreviousDay() {
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1, yesterday.getDay() - 1);

  return yesterday;
}

export const getWorkoutData = (data, stationNumber, mustlegroup) => {
  return (dispatch) => {
    let fromData = moment().subtract(1, "days").format("MMMM Do YYYY");
    let toData = moment().format("MMMM Do YYYY");
    console.log(fromData);
    console.log(toData);
    var config = {
      method: "post",
      url: `${BACKEND_URL}/Station/getAllStations?from=${fromData}&to=${toData}&stationNumber=${Number(
        stationNumber
      )}&mustleGroup=${mustlegroup}`,
      data: data,
    };
    axios(config)
      .then(function (response) {
        // console.log(response, "response/ghghhhg");
        dispatch(getStationfromdata(response.data));
        // dispatch(getfilteredWorkout(response?.data?.data));
        // cleanState();
        // setloader(false);
      })
      .catch(function (error) {
        console.log(error, "err");
        // dispatch(getStation([]));
        // setloader(false);
        // toast.error(error.response?.data?.message);
      });
  };
};

function formatDate(date) {
  var dd = date.getDate();
  var mm = date.getMonth() + 1;
  var yyyy = date.getFullYear();
  if (dd < 10) {
    dd = "0" + dd;
  }
  if (mm < 10) {
    mm = "0" + mm;
  }
  date = mm + "/" + dd + "/" + yyyy;
  return date;
}

function previousweek(days) {
  const today = new Date();
  const nextweek = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate() - days
  );
  return nextweek;
}

export const getFilteredWorkout = (data, mustleType, routineName) => {
  return (dispatch) => {
    var config = {
      method: "post",
      url: `${BACKEND_URL}/Station/getFilteredWorkout?${
        routineName !== "" ? `mustleGroup=${routineName}` : ""
      }${
        mustleType !== ""
          ? `${routineName !== "" ? "&" : ""}mustleType=${mustleType}`
          : ""
      }`,
      data: data,
    };
    axios(config)
      .then(function (response) {
        console.log(response.data, "response/ghghhhg");
        dispatch(workoutRecordfn(response.data?.workout));
        // dispatch(getfilteredWorkout(response?.data?.data));
        // cleanState();
        // setloader(false);
      })
      .catch(function (error) {
        console.log(error, "err");
        dispatch(workoutRecordfn([]));
        // dispatch(getStation([]));
        // setloader(false);
        // toast.error(error.response?.data?.message);
      });
  };
};

export const addWorkoutfn = (arr, setObject) => {
  return (dispatch) => {
    var config = {
      method: "post",
      url: `${BACKEND_URL}/Station/addStation`,
      data: arr,
    };
    var config2 = {
      method: "post",
      url: `${BACKEND_URL}/Station/getMustleGroup?stationNumber=${arr?.stationNumber}&mustlegroup=${arr?.mustleGroup}`,
      data: {
        userId: arr?.userId,
      },
    };
    var d = new Date();
    var n = d.toLocaleString("en-US", { weekday: "long" });
    const date = new Date();
    const day1 = date.getDay();
    var config3 = {
      method: "post",
      url: `${BACKEND_URL}/createStation/createWorkoutStation`,
      data: {
        mustleType: arr?.mustleType,
        mustleGroup: arr?.mustleGroup,
        userId: arr?.userId,
        stationNumber: arr?.stationNumber,
        workoutRoutineNumber: [day1],
        workoutOrder: 1,
        workoutDays: [n.toLowerCase()],
      },
    };
    axios(config2)
      .then(function (response) {
        console.log(response, "response,response");
        if (response.data?.message === "station doesn't exist!") {
          axios(config3)
            .then((response) => {
              axios(config)
                .then(function (response) {
                  console.log(response, "response/ghghhhg");
                  // dispatch(getfilteredWorkout(response?.data?.data));
                  dispatch(
                    getWorkoutData(
                      {
                        userId: arr?.userId,
                      },
                      Number(arr?.stationNumber),
                      arr?.mustleGroup
                    )
                  );
                  // setObject({
                  //   Reps: "",
                  //   Weight: "",
                  //   time: "",
                  // });
                  toast.success("workout added successfully");
                  // cleanState();
                  // setloader(false);
                })
                .catch(function (error) {
                  console.log(error, "err");
                  // dispatch(getStation([]));
                  // setloader(false);
                  toast.error(error.response?.data?.message);
                });
            })
            .catch((error) => console.log(error));
        } else {
          axios(config)
            .then(function (response) {
              // console.log(response, "response/ghghhhg");
              // dispatch(getfilteredWorkout(response?.data?.data));
              dispatch(
                getWorkoutData(
                  {
                    userId: arr?.userId,
                  },
                  Number(arr?.stationNumber),
                  arr?.mustleGroup
                )
              );
              // setObject({
              //   Reps: "",
              //   Weight: "",
              //   time: "",
              // });
              toast.success("workout added successfully");
              // cleanState();
              // setloader(false);
            })
            .catch(function (error) {
              console.log(error, "err");
              // dispatch(getStation([]));
              // setloader(false);
              toast.error(error.response?.data?.message);
            });
        }
      })
      .catch(function (error) {
        console.log(error, "err");
        // dispatch(getStation([]));
        // setloader(false);
        toast.error(error.response?.data?.message);
      });
  };
};

export const getstationdatafn = (id, setStataionData) => {
  return (dispatch) => {
    var config = {
      method: "get",
      url: `${BACKEND_URL}/createStation/getStation?id=${id}`,
    };
    axios(config)
      .then(function (response) {
        // console.log(response, "response/ghghhhg");
        setStataionData(response.data.station[0]);
      })
      .catch(function (error) {
        console.log(error, "err");
        // toast.error(error.response?.data?.message);
      });
  };
};

export const getDay = () => {
  let day;
  switch (new Date().getDay()) {
    case 0:
      day = "Sunday";
      break;
    case 1:
      day = "Monday";
      break;
    case 2:
      day = "Tuesday";
      break;
    case 3:
      day = "Wednesday";
      break;
    case 4:
      day = "Thursday";
      break;
    case 5:
      day = "Friday";
      break;
    case 6:
      day = "Saturday";
  }
  return day.toLowerCase();
};
